import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { TaskListCustomFilterDTO2, TaskListCustomFilterDTO2Type } from '../../api/api';
import { AddIcon } from '../../assets/icons/AddIcon';
import Button from '../../components/button/Button';
import CircularProgress from '../../components/circular-progress/CircularProgress';
import DialogView from '../../components/dialog-view/DialogView';
import IconButton from '../../components/icon-button/IconButton';
import { MenuItem, Select } from '../../components/select/Select';
import TextField from '../../components/text-field/TextField';
import { PeopleIcon } from '../../assets/icons/PeopleIcon';
import Checkbox from '../../components/checkbox/Checkbox';
import { selectUserProfile } from '../../stateManagement/reducers/userProfileReducer';
import Typography from '../../components/typography/Typography';
import { useConfirmationDialog } from '../../hooks/useConfirmationDialog';
import { DialogBody } from '../../stateManagement/reducers/confirmDialogReducer';
import { selectShowAddFilterButton } from '../../stateManagement/reducers/taskListReducer';
import { TableTab } from '../table/TableUtils';
import { DefaultTabs } from '../../views/task-view/task-list-view/useTaskList';
import { getUniqueIdFromCustomFilter } from '../../views/task-view/task-list-view/TaskListFilterUtils';
import { DeleteIcon } from '../../assets/icons/DeleteIcon';

interface Props {
  filters: TaskListCustomFilterDTO2[];
  selectedFilter?: TaskListCustomFilterDTO2;
  onSelectFilter: (filter: TaskListCustomFilterDTO2) => void;
  onSaveFilter: (filterName: string, successfulSaveCallback: () => void, type: TaskListCustomFilterDTO2Type) => void;
  updatingFilter: boolean;
  onDeleteFilter: (filter: TaskListCustomFilterDTO2) => void;
  onUpdateFilter: (filter: TaskListCustomFilterDTO2) => void;
  activeTab?: TableTab;
}

const CustomFilterPickerServer = (props: Props) => {
  const {
    filters,
    selectedFilter,
    onSelectFilter,
    onSaveFilter,
    updatingFilter,
    onDeleteFilter,
    onUpdateFilter,
    activeTab
  } = props;

  const [filterName, setFilterName] = useState('');
  const [isDepartmentFilter, setIsDepartmentFilter] = useState(false);
  const [showSaveFilterDialog, setShowSaveFilterDialog] = useState(false);
  const userDepartment = useSelector(selectUserProfile).userProfile?.department;
  const showAddFilterButton = useSelector(selectShowAddFilterButton);
  const { getConfirmation } = useConfirmationDialog();

  const handleSelectFilter = (uniqueId: string) => {
    const newFilter = filters.find((f) => getUniqueIdFromCustomFilter(f) === uniqueId);
    if (!!newFilter) {
      onSelectFilter && onSelectFilter(newFilter);
    }
  };

  const onSaveFilterCallback = useCallback(() => {
    setShowSaveFilterDialog(false);
    setFilterName('');
  }, []);

  const handleOnSaveFilter = () => {
    let customFilterType;
    const isAllTaskTab = activeTab?.id === DefaultTabs.All.id;
    if (isAllTaskTab) {
      if (isDepartmentFilter) {
        customFilterType = TaskListCustomFilterDTO2Type.DepartmentAllTasks;
      } else {
        customFilterType = TaskListCustomFilterDTO2Type.UserAllTasks;
      }
    } else {
      if (isDepartmentFilter) {
        customFilterType = TaskListCustomFilterDTO2Type.Department;
      } else {
        customFilterType = TaskListCustomFilterDTO2Type.User;
      }
    }

    onSaveFilter(filterName, onSaveFilterCallback, customFilterType);
  };

  const handleEnterPressed = (event: any) => {
    event.stopPropagation();
    if (event.key === 'Enter') {
      handleOnSaveFilter();
    }
  };

  const handleDeleteFilter = useCallback(
    async (e: any, filter: TaskListCustomFilterDTO2) => {
      e.stopPropagation();
      const dialogBodyText =
        filter.type === TaskListCustomFilterDTO2Type.User || filter.type === TaskListCustomFilterDTO2Type.UserAllTasks
          ? 'Vil du slette dit personlige filter?'
          : `Filteret bliver delt på tværs af din afdeling og vil blive slettet for alle i din afdeling: ${userDepartment?.name}`;

      const confirmation = await getConfirmation({
        headerText: `Vil du slette filteret: ${filter.name}`,
        bodyText: `${dialogBodyText}`,
        declineButtonText: 'Fortryd',
        confirmButtonText: 'Slet'
      } as DialogBody);

      if (confirmation === 'confirm') {
        onDeleteFilter(filter);
      }
    },
    [getConfirmation, onDeleteFilter, userDepartment?.name]
  );

  const updateFilter = useCallback(async () => {
    if (!selectedFilter) return;
    const dialogBodyText =
      selectedFilter.type === TaskListCustomFilterDTO2Type.Department ||
      selectedFilter.type === TaskListCustomFilterDTO2Type.DepartmentAllTasks
        ? `Filteret bliver delt på tværs af din afdeling og vil blive opdateret for alle i din afdeling: ${userDepartment?.name}`
        : 'Vil du opdatere dit personlige filter?';

    const confirmation = await getConfirmation({
      headerText: `Vil du opdatere filteret: ${selectedFilter.name}`,
      bodyText: `${dialogBodyText}`,
      declineButtonText: 'Fortryd',
      confirmButtonText: 'Opdater'
    } as DialogBody);

    if (confirmation === 'confirm') {
      onUpdateFilter(selectedFilter);
    }
  }, [getConfirmation, onUpdateFilter, selectedFilter, userDepartment]);

  return (
    <Container>
      <Select
        fullWidth
        label="Vælg filter"
        onChange={(e: any) => handleSelectFilter(e.target.value)}
        value={selectedFilter ? getUniqueIdFromCustomFilter(selectedFilter) : ''}
        compact
        renderValue={() => (
          <div>
            {selectedFilter &&
              (selectedFilter?.type === TaskListCustomFilterDTO2Type.Department ||
                selectedFilter?.type === TaskListCustomFilterDTO2Type.DepartmentAllTasks) && (
                <StyledPeopleIcon size="14px" />
              )}
            {selectedFilter?.name ?? ''}
          </div>
        )}
      >
        {filters.map((filter) => (
          <MenuItem key={getUniqueIdFromCustomFilter(filter)} value={getUniqueIdFromCustomFilter(filter)}>
            <ItemContainer>
              <Typography>
                {(filter.type === TaskListCustomFilterDTO2Type.Department ||
                  filter.type === TaskListCustomFilterDTO2Type.DepartmentAllTasks) && <StyledPeopleIcon size="16px" />}
                {filter.name}
              </Typography>
              {!updatingFilter ? (
                <StyledIconButton onClick={(e: any) => handleDeleteFilter(e, filter)}>
                  <DeleteIcon size="18px" />
                </StyledIconButton>
              ) : (
                <StyledIconButton>
                  <CircularProgress margin="0" size="14px" />
                </StyledIconButton>
              )}
            </ItemContainer>
          </MenuItem>
        ))}
      </Select>
      {showAddFilterButton && (
        <ButtonContainer>
          <StyledIconButton variant="outlined" padding="8px" onClick={() => setShowSaveFilterDialog(true)}>
            <AddIcon size="16px" />
          </StyledIconButton>
          {!!selectedFilter && (
            <Button variant="tertiary" compact square={false} onClick={() => updateFilter()} isLoading={updatingFilter}>
              Opdater filter
            </Button>
          )}
        </ButtonContainer>
      )}
      {showSaveFilterDialog && (
        <DialogView>
          <DialogContainer>
            <TextField
              label="Vælg navn til filteret"
              value={filterName}
              onChange={(e) => setFilterName(e.target.value)}
              onKeyUp={handleEnterPressed}
            />

            <Checkbox
              label={'Afdelingsfilter: ' + userDepartment?.name}
              icon={<StyledPeopleIcon size="16px" />}
              onChange={() => setIsDepartmentFilter(!isDepartmentFilter)}
              checked={isDepartmentFilter}
            />

            <ButtonRow>
              <Button variant="secondary" onClick={() => setShowSaveFilterDialog(false)}>
                Annuller
              </Button>
              <Button variant="primary" isLoading={updatingFilter} onClick={() => handleOnSaveFilter()}>
                Gem
              </Button>
            </ButtonRow>
          </DialogContainer>
        </DialogView>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 400px;
  align-items: center;
  height: 37px;
`;
const ButtonContainer = styled.div`
  display: flex;
  padding: 8px;
`;

const DialogContainer = styled.div`
  display: flex;
  padding: 48px;
  row-gap: 12px;
  flex-direction: column;
`;

const ButtonRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

const StyledPeopleIcon = styled(PeopleIcon)`
  margin-right: ${(props) => props.theme.spacing(2)};
`;

const StyledIconButton = styled(IconButton)`
  margin-right: ${(props) => props.theme.spacing(2)};
  padding: ${(props) => props.theme.spacing(2)};
`;

export default CustomFilterPickerServer;
