import { useCallback, useEffect, useMemo, useState } from 'react';
import { FilterProps, useAsyncDebounce } from 'react-table';
import { InputAdornment } from '@mui/material';

import DateRangePicker from '../../../components/date-range-picker/DateRangePicker';
import {
  AssetType2,
  FromToDTO,
  GisRegion2,
  OrderByColumnEnum,
  TaskListCustomFilterDTO,
  TaskListCustomFilterDTO2,
  TaskListCustomFilterDTO2Type,
  TaskListRequestDTO,
  WorkTaskStatus
} from '../../../api/api';
import { ColumnAccessors } from './useTaskList';
import { useDispatch, useSelector } from 'react-redux';
import { getEnumDisplayValue } from '../../../utils/enumUtils';
import {
  selectDepartments,
  selectFilterValues,
  selectSelectedCustomFilter,
  selectOperationBaseFilter,
  selectTaskTypes,
  setFilterValues,
  setShowFilterButton
} from '../../../stateManagement/reducers/taskListReducer';
import Select, { MenuItem } from '../../../components/select/Select';
import { SearchIcon } from '../../../assets/icons/SearchIcon';
import TextField from '../../../components/text-field/TextField';
import ToolTip from '../../../components/tool-tip/ToolTip';
import { formatDate } from '../../../utils/dateHandling';
import { isDevice } from '../../../utils/device-handling/deviceDetectionUtils';
import styled from 'styled-components';

export const getFilterValues = (filterValues: TaskListRequestDTO, value: any, column?: OrderByColumnEnum | string) => {
  switch (column) {
    // String values
    case OrderByColumnEnum.WorkTaskId:
    case ColumnAccessors.Id:
      return { ...filterValues, workTaskIdFilter: value };
    case OrderByColumnEnum.TaskLocation:
    case ColumnAccessors.Location:
      return { ...filterValues, locationFilter: value };
    case OrderByColumnEnum.ZipCode:
    case ColumnAccessors.ZipCode:
      return { ...filterValues, zipCodeFilter: value };
    case OrderByColumnEnum.AssetId:
    case ColumnAccessors.AssetId:
      return { ...filterValues, assetIdFilter: value };
    case OrderByColumnEnum.NotesForPlanning:
    case ColumnAccessors.NotesForPlanning:
      return { ...filterValues, notesForPlanningFilter: value };
    case OrderByColumnEnum.ProjectNumber:
    case ColumnAccessors.ProjectNumber:
      return { ...filterValues, projectNumberFilter: value };
    case OrderByColumnEnum.CreatedByName:
    case ColumnAccessors.CreatedBy:
      return { ...filterValues, createdByFilter: value };
    case OrderByColumnEnum.AssignedToName:
    case ColumnAccessors.AssignedTo:
      return { ...filterValues, assignedToTechnicianFilter: value };
    case OrderByColumnEnum.AssignedToEmail:
    case ColumnAccessors.AssignedToEmail:
      return { ...filterValues, assignedToTechnicianEmailFilter: value };
    case OrderByColumnEnum.AssignedToCaseWorkerName:
    case ColumnAccessors.CaseWorker:
      return { ...filterValues, assignedToCaseWorkerFilter: value };

    // Select values
    case OrderByColumnEnum.Status:
    case ColumnAccessors.Status:
      return { ...filterValues, taskStatusFilter: value };
    case OrderByColumnEnum.Type:
    case ColumnAccessors.TaskType:
      return { ...filterValues, taskTypesFilter: value };
    case OrderByColumnEnum.AssignedToDepartment:
    case ColumnAccessors.Department:
      return { ...filterValues, departmentIdFilter: value };
    case OrderByColumnEnum.AssetType:
    case ColumnAccessors.AssetType:
      return { ...filterValues, assetTypeFilter: value };
    case OrderByColumnEnum.GisRegion:
    case ColumnAccessors.Region:
      return { ...filterValues, regionFilter: value };
    case OrderByColumnEnum.AppointmentRequest:
    case ColumnAccessors.AppointmentRequest:
      return { ...filterValues, appointmentRequestFilter: value };

    // Date values
    case OrderByColumnEnum.CreatedAt:
    case ColumnAccessors.CreationDate:
      return { ...filterValues, createdDateFilter: value };
    case OrderByColumnEnum.EarliestStartDate:
    case ColumnAccessors.EarliestStartDate:
      return { ...filterValues, earliestStartDateFilter: value };
    case OrderByColumnEnum.Deadline:
    case ColumnAccessors.Deadline:
      return { ...filterValues, deadlineFilter: value };
    case OrderByColumnEnum.PlannedArrival:
    case ColumnAccessors.PlannedArrival:
      return { ...filterValues, plannedArrivalFilter: value };
    case ColumnAccessors.CompletedAt:
    case OrderByColumnEnum.CompletedDate:
      return { ...filterValues, completedDateFilter: value };
    default:
      return filterValues;
  }
};

const getFilterValueByColumn = (filterValues: TaskListRequestDTO, column: OrderByColumnEnum) => {
  switch (column) {
    // String values
    case OrderByColumnEnum.WorkTaskId:
      return filterValues.workTaskIdFilter;
    case OrderByColumnEnum.TaskLocation:
      return filterValues.locationFilter;
    case OrderByColumnEnum.ZipCode:
      return filterValues.zipCodeFilter;
    case OrderByColumnEnum.AssetId:
      return filterValues.assetIdFilter;
    case OrderByColumnEnum.NotesForPlanning:
      return filterValues.notesForPlanningFilter;
    case OrderByColumnEnum.ProjectNumber:
      return filterValues.projectNumberFilter;
    case OrderByColumnEnum.CreatedByName:
      return filterValues.createdByFilter;
    case OrderByColumnEnum.AssignedToName:
      return filterValues.assignedToTechnicianFilter;
    case OrderByColumnEnum.AssignedToEmail:
      return filterValues.assignedToTechnicianEmailFilter;
    case OrderByColumnEnum.AssignedToCaseWorkerName:
      return filterValues.assignedToCaseWorkerFilter;

    // Select values
    case OrderByColumnEnum.Status:
      return filterValues.taskStatusFilter;
    case OrderByColumnEnum.Type:
      return filterValues.taskTypesFilter;
    case OrderByColumnEnum.AssignedToDepartment:
      return filterValues.departmentIdFilter;
    case OrderByColumnEnum.AssetType:
      return filterValues.assetTypeFilter;
    case OrderByColumnEnum.GisRegion:
      return filterValues.regionFilter;
    case OrderByColumnEnum.AppointmentRequest:
      return filterValues.appointmentRequestFilter;

    // Date values
    case OrderByColumnEnum.CreatedAt:
      return filterValues.createdDateFilter;
    case OrderByColumnEnum.EarliestStartDate:
      return filterValues.earliestStartDateFilter;
    case OrderByColumnEnum.Deadline:
      return filterValues.deadlineFilter;
    case OrderByColumnEnum.PlannedArrival:
      return filterValues.plannedArrivalFilter;
    case OrderByColumnEnum.CompletedDate:
      return filterValues.completedDateFilter;
  }
};

export const transformTaskListRequestToCustomFilter2 = (
  filterValues: TaskListRequestDTO,
  name?: string,
  type?: TaskListCustomFilterDTO2Type
) => {
  return {
    name: name,
    isSelected: true,
    workTaskIdFilter: filterValues.workTaskIdFilter,
    taskTypesFilter: filterValues.taskTypesFilter,
    zipCodeFilter: filterValues.zipCodeFilter,
    assetIdFilter: filterValues.assetIdFilter,
    regionFilter: filterValues.regionFilter,
    notesForPlanningFilter: filterValues.notesForPlanningFilter,
    projectNumberFilter: filterValues.projectNumberFilter,
    createdByFilter: filterValues.createdByFilter,
    assignedToTechnicianFilter: filterValues.assignedToTechnicianFilter,
    assignedToTechnicianEmailFilter: filterValues.assignedToTechnicianEmailFilter,
    assignedToCaseWorkerFilter: filterValues.assignedToCaseWorkerFilter,
    departmentIdFilter: filterValues.departmentIdFilter,
    taskStatusFilter: filterValues.taskStatusFilter,
    assetTypeFilter: filterValues.assetTypeFilter,
    earliestStartDateFilter: filterValues.earliestStartDateFilter,
    deadlineFilter: filterValues.deadlineFilter,
    plannedArrivalFilter: filterValues.plannedArrivalFilter,
    createdDateFilter: filterValues.createdDateFilter,
    locationFilter: filterValues.locationFilter,
    type: type
  } as TaskListCustomFilterDTO2;
};

const transformTaskListRequestToCustomFilter = (filterValues: TaskListRequestDTO | TaskListCustomFilterDTO2) => {
  return {
    workTaskIdFilter: filterValues.workTaskIdFilter,
    taskTypesFilter: filterValues.taskTypesFilter,
    zipCodeFilter: filterValues.zipCodeFilter,
    assetIdFilter: filterValues.assetIdFilter,
    regionFilter: filterValues.regionFilter,
    notesForPlanningFilter: filterValues.notesForPlanningFilter,
    projectNumberFilter: filterValues.projectNumberFilter,
    createdByFilter: filterValues.createdByFilter,
    assignedToTechnicianFilter: filterValues.assignedToTechnicianFilter,
    assignedToTechnicianEmailFilter: filterValues.assignedToTechnicianEmailFilter,
    assignedToCaseWorkerFilter: filterValues.assignedToCaseWorkerFilter,
    departmentIdFilter: filterValues.departmentIdFilter,
    taskStatusFilter: filterValues.taskStatusFilter,
    assetTypeFilter: filterValues.assetTypeFilter,
    earliestStartDateFilter: filterValues.earliestStartDateFilter,
    deadlineFilter: filterValues.deadlineFilter,
    plannedArrivalFilter: filterValues.plannedArrivalFilter,
    createdDateFilter: filterValues.createdDateFilter,
    locationFilter: filterValues.locationFilter
  } as TaskListCustomFilterDTO;
};

function areTaskListFilterEqual(baseFilter: TaskListCustomFilterDTO, activeFilter: TaskListCustomFilterDTO): boolean {
  const isEqual = (basePropertyValue: any, activePropertyValue: any): boolean => {
    if (basePropertyValue === activePropertyValue) return true;

    if (
      (basePropertyValue === null ||
        basePropertyValue === '' ||
        basePropertyValue === undefined ||
        (Array.isArray(basePropertyValue) && basePropertyValue.length === 0)) &&
      (activePropertyValue === null ||
        activePropertyValue === '' ||
        activePropertyValue === undefined ||
        (Array.isArray(activePropertyValue) && activePropertyValue.length === 0))
    ) {
      return true;
    }
    return false;
  };

  const baseProp = Object.keys(baseFilter);
  const activeProp = Object.keys(activeFilter);

  if (baseProp.length !== activeProp.length) {
    return false;
  }

  for (let key of baseProp) {
    if (!isEqual(baseFilter[key as keyof TaskListCustomFilterDTO], activeFilter[key as keyof TaskListCustomFilterDTO])) {
      return false;
    }
  }

  return true;
}

const getShowFilterButton = (
  activeFilter: TaskListRequestDTO,
  selectedCustomFilter?: TaskListCustomFilterDTO2,
  baseFilter?: TaskListRequestDTO
) => {
  if (!selectedCustomFilter && !baseFilter) return;

  const activeFilterValues = transformTaskListRequestToCustomFilter(activeFilter);

  if (!selectedCustomFilter && baseFilter) {
    const baseFilterValues = transformTaskListRequestToCustomFilter(baseFilter);
    return !areTaskListFilterEqual(baseFilterValues, activeFilterValues);
  }

  if (selectedCustomFilter) {
    const selectedCutsomFilterValues = transformTaskListRequestToCustomFilter(selectedCustomFilter);
    return !areTaskListFilterEqual(selectedCutsomFilterValues, activeFilterValues);
  }
};

export const SelectColumnFilter = (tableInstance: FilterProps<any>, hideAll?: boolean) => {
  const { Header } = tableInstance.column;

  const isTablet = isDevice();

  const column = Object.values(OrderByColumnEnum).find((c) => getEnumDisplayValue(c) === Header);
  const filterValues = useSelector(selectFilterValues);
  const operationFilterValues = useSelector(selectOperationBaseFilter);
  const selectedCustomFilter = useSelector(selectSelectedCustomFilter);

  const filterValue = useMemo(() => {
    return !!column ? getFilterValueByColumn(filterValues, column) : [];
  }, [column, filterValues]);

  const operationFilterValue = useMemo(() => {
    if (filterValues.tagId !== undefined) {
      return !!column ? getFilterValueByColumn(operationFilterValues, column) : [];
    } else {
      return undefined;
    }
  }, [column, filterValues.tagId, operationFilterValues]);

  const [options, setOptions] = useState<Map<any, string>>(new Map());
  const [value, setValue] = useState(filterValue ?? []);

  const departments = useSelector(selectDepartments);
  const taskTypes = useSelector(selectTaskTypes);

  const dispatch = useDispatch();

  const getIsMarked = useCallback(() => {
    if (filterValue && filterValue.toString().length > 0) {
      const selectedValue = filterValue as any[];
      const defaultValue = operationFilterValue as any[];
      if (!selectedValue && !defaultValue) return false;
      if (selectedValue?.length !== defaultValue?.length) return true;

      const sortedFilterValue = selectedValue.slice().sort();
      const sortedOperationFilterValue = defaultValue.slice().sort();

      return !sortedFilterValue.every((value, index) => value === sortedOperationFilterValue[index]);
    } else {
      return !!operationFilterValue;
    }
  }, [filterValue, operationFilterValue]);

  const [marked, setMarked] = useState<boolean>(getIsMarked());

  useEffect(() => {
    setValue(filterValue ?? []);
    setMarked(getIsMarked());
  }, [filterValue, getIsMarked, operationFilterValue]);

  useEffect(() => {
    let _options: Map<any, string> = new Map();
    switch (column) {
      case OrderByColumnEnum.Type:
        if (taskTypes.length > 0) _options = new Map(taskTypes.map((t) => [t.id, t.name ?? '']));
        break;
      case OrderByColumnEnum.AssignedToDepartment:
        if (departments.length > 0) _options = new Map(departments.map((d) => [d.departmentId, d.name ?? '']));
        break;
      case OrderByColumnEnum.Status:
        _options = new Map(Object.values(WorkTaskStatus).map((c) => [c, getEnumDisplayValue(c)]));
        break;
      case OrderByColumnEnum.GisRegion:
        _options = new Map(Object.values(GisRegion2).map((r) => [r, getEnumDisplayValue(r)]));
        break;
      case OrderByColumnEnum.AssetType:
        _options = new Map(Object.values(AssetType2).map((a) => [a, getEnumDisplayValue(a)]));
        break;
      case OrderByColumnEnum.AppointmentRequest:
        _options = new Map([
          [true, 'Ja'],
          [false, 'Nej']
        ]);
        break;
      default:
        break;
    }
    setOptions(_options);
  }, [column, departments, taskTypes]);

  const handleOnChange = useCallback(
    (_values: any) => {
      setValue(_values);
      setMarked(getIsMarked());
      const _column = Object.values(OrderByColumnEnum).find((c) => getEnumDisplayValue(c) === Header);
      let newFilterValues = filterValues;
      if (_values.includes('clear')) {
        newFilterValues = getFilterValues({ ...filterValues, page: 1 }, [], _column);
        setValue([]);
        setMarked(!!operationFilterValue);
      } else {
        newFilterValues = getFilterValues({ ...filterValues, page: 1 }, _values, _column);
      }
      dispatch(setFilterValues(newFilterValues));
      dispatch(
        setShowFilterButton(getShowFilterButton(newFilterValues, selectedCustomFilter, operationFilterValues) ?? false)
      );
    },
    [Header, dispatch, filterValues, getIsMarked, operationFilterValues, operationFilterValue, selectedCustomFilter]
  );

  const mapValues = useCallback(() => {
    let selectedValues;
    switch (column) {
      case OrderByColumnEnum.Type:
      case OrderByColumnEnum.AssignedToDepartment:
        selectedValues = Array.from(options)
          .filter(([v, _]) => (value as number[]).some((val) => val === v))
          .map(([_, k]) => `${k}`)
          .join('; ');
        break;
      case OrderByColumnEnum.Status:
        selectedValues = Array.from(options)
          .filter(([v, _]) => (value as WorkTaskStatus[]).some((val) => val === v))
          .map(([_, k]) => `${k}`)
          .join('; ');
        break;
      case OrderByColumnEnum.GisRegion:
        selectedValues = Array.from(options)
          .filter(([v, _]) => (value as GisRegion2[]).some((val) => val === v))
          .map(([_, k]) => `${k}`)
          .join('; ');
        break;
      case OrderByColumnEnum.AssetType:
        selectedValues = Array.from(options)
          .filter(([v, _]) => (value as AssetType2[]).some((val) => val === v))
          .map(([_, k]) => `${k}`)
          .join('; ');
        break;
      case OrderByColumnEnum.AppointmentRequest:
        selectedValues = Array.from(options)
          .filter(([v, _]) => (value as boolean[]).some((val) => val === v))
          .map(([_, k]) => `${k}`)
          .join('; ');
        break;
      default:
        selectedValues = '';
        break;
    }
    return selectedValues;
  }, [column, options, value]);

  return (
    <ToolTip
      title={Header + (value === null || value === undefined || value.toString() === '' ? '' : ` (${mapValues()})`)}
      hidden={isTablet}
      placement="top-start"
    >
      <SelectDiv>
        <Select
          onChange={(e) => handleOnChange(e.target.value || undefined)}
          multiple
          label={Header}
          compact
          fullWidth
          inputProps={{ 'aria-label': 'Without label' }}
          value={value}
          marked={marked}
        >
          {!hideAll && <MenuItem value="clear">Alle</MenuItem>}
          {[...options].map(([_key, _value]) => {
            return (
              <MenuItem key={_key} style={{ whiteSpace: 'pre-wrap' }} value={_key}>
                {_value}
              </MenuItem>
            );
          })}
        </Select>
      </SelectDiv>
    </ToolTip>
  );
};

export const SearchColumnFilter = (tableInstance: FilterProps<any>) => {
  const { Header } = tableInstance.column;
  const filterValues = useSelector(selectFilterValues);
  const selectedCustomFilter = useSelector(selectSelectedCustomFilter);
  const operationBase = useSelector(selectOperationBaseFilter);
  const isTablet = isDevice();

  const dispatch = useDispatch();

  const column = Object.values(OrderByColumnEnum).find((c) => getEnumDisplayValue(c) === Header);

  const [value, setValue] = useState<string | undefined>();

  const filterValue = useMemo(() => {
    return !!column ? getFilterValueByColumn(filterValues, column) : [];
  }, [column, filterValues]);

  useEffect(() => {
    if (typeof filterValue === 'string') setValue(filterValue);
    else setValue(undefined);
  }, [filterValue]);

  const fetchTextTasks = useCallback(() => {
    const _column = Object.values(OrderByColumnEnum).find((c) => getEnumDisplayValue(c) === Header);
    const textValues = getFilterValues({ ...filterValues, page: 1 }, value, _column);
    dispatch(setFilterValues(textValues));
    dispatch(setShowFilterButton(getShowFilterButton(textValues, selectedCustomFilter, operationBase) ?? false));
  }, [Header, dispatch, filterValues, operationBase, selectedCustomFilter, value]);

  const onChange = useAsyncDebounce((_value?: string) => {
    fetchTextTasks();
  }, 500);

  return (
    <ToolTip placement="top-start" hidden={isTablet} title={Header + (value !== undefined ? `: ${value}` : '')}>
      <div>
        <TextField
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          label={Header}
          compact
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon size="18px" color="grey" />
              </InputAdornment>
            )
          }}
          value={value || ''}
          marked={!!value}
        />
      </div>
    </ToolTip>
  );
};

export const DateRangeColumnFilter = (tableInstance: FilterProps<any>, alignRight?: boolean, hasDeadlineDate?: boolean) => {
  const { Header } = tableInstance.column;
  const filterValues = useSelector(selectFilterValues);
  const selectedCustomFilter = useSelector(selectSelectedCustomFilter);
  const operationBase = useSelector(selectOperationBaseFilter);
  const isTablet = isDevice();

  const dispatch = useDispatch();

  const column = Object.values(OrderByColumnEnum).find((c) => getEnumDisplayValue(c) === Header);

  const [value, setValue] = useState<FromToDTO | undefined>();

  const filterValue = useMemo(() => {
    return !!column ? getFilterValueByColumn(filterValues, column) : [];
  }, [column, filterValues]);

  useEffect(() => {
    let newValue = filterValue as FromToDTO;
    setValue(newValue);
  }, [filterValue, value?.from, value?.to]);

  const handleDateChange = useCallback(
    (from?: Date, to?: Date) => {
      const _column = Object.values(OrderByColumnEnum).find((c) => getEnumDisplayValue(c) === Header);
      if (from || to) {
        const selectedDates = { from: from?.toISOString() + '', to: to?.toISOString() + '' } as FromToDTO;
        setValue(selectedDates);
        const newFilterValues = getFilterValues({ ...filterValues, page: 1 }, selectedDates, _column);
        dispatch(setFilterValues(newFilterValues));
        dispatch(setShowFilterButton(getShowFilterButton(newFilterValues, selectedCustomFilter, operationBase) ?? false));
      }
      if (!from && !to && _column) {
        setValue(undefined);
        const currentValue = getFilterValueByColumn(filterValues, _column);
        if (currentValue !== undefined) {
          const newFilterValues = getFilterValues({ ...filterValues, page: 1 }, undefined, _column);
          dispatch(setFilterValues(newFilterValues));
          dispatch(setShowFilterButton(getShowFilterButton(newFilterValues, selectedCustomFilter, operationBase) ?? false));
        }
      }
    },
    [Header, dispatch, filterValues, operationBase, selectedCustomFilter]
  );

  return (
    <ToolTip
      title={
        Header +
        (value !== undefined && value?.from && value?.to
          ? `: ${formatDate(new Date(value?.from ?? ''))}-${formatDate(new Date(value?.to ?? ''))}`
          : '')
      }
      hidden={isTablet}
      placement="top-start"
    >
      <div>
        <DateRangePicker
          compact
          right={alignRight ? '0' : undefined}
          label={Header as string}
          onDateChanged={handleDateChange}
          hasDeadlineDate={hasDeadlineDate}
          disabled={false}
          filterValue={value}
          marked={!!value}
        />
      </div>
    </ToolTip>
  );
};

export const getUniqueIdFromCustomFilter = (filter?: TaskListCustomFilterDTO2): string => {
  return (filter?.name ?? '.') + ':' + filter?.type ?? '.';
};

const SelectDiv = styled.div`
  width: 100%;
`;

export const isInPlanningLoop = (statusArray: WorkTaskStatus[] | string[]) => {
  return statusArray.some(
    (status) =>
      status === WorkTaskStatus.ReadyForPlanning ||
      status === WorkTaskStatus.Ongoing ||
      status === WorkTaskStatus.OnRoute ||
      status === WorkTaskStatus.Planned ||
      status === WorkTaskStatus.PlannedAppointment ||
      status === WorkTaskStatus.Completed ||
      status === getEnumDisplayValue(WorkTaskStatus.ReadyForPlanning) ||
      status === getEnumDisplayValue(WorkTaskStatus.Ongoing) ||
      status === getEnumDisplayValue(WorkTaskStatus.OnRoute) ||
      status === getEnumDisplayValue(WorkTaskStatus.Planned) ||
      status === getEnumDisplayValue(WorkTaskStatus.PlannedAppointment) ||
      status === getEnumDisplayValue(WorkTaskStatus.Completed)
  );
};
