import styled from 'styled-components';
import Typography from '../../../../../components/typography/Typography';
import { Section, SectionHeader } from '../../../../../styling/FormStyling';
import { ColumnFlex3 } from '../../../../../styling/Styling';
import { StyledSectionContent } from '../TaskDetailsStep';
import { TaskDetailsStepRelatedTask, TaskRelationType } from './TaskDetailsStepRelatedTask';
import { WorkTaskDTOExtended } from '../../../../../models/TaskType';
import { SubWorkTaskDTO, WorkTaskStatus } from '../../../../../api/api';
import useWorkTaskLock from '../../../../../hooks/useWorkTaskLock';
import { useEffect } from 'react';
import TaskService from '../../../../../services/TaskService';
import { setListShouldUpdate } from '../../../../../stateManagement/reducers/taskListReducer';
import { useDispatch } from 'react-redux';

interface Props {
  task?: WorkTaskDTOExtended;
  subWorkTasks?: SubWorkTaskDTO[];
  setSubWorkTasks: (subWorkTasks?: SubWorkTaskDTO[]) => void;
}

export const SubTasks = (props: Props) => {
  const { task, subWorkTasks, setSubWorkTasks } = props;
  const { releaseWorkTaskLock } = useWorkTaskLock();
  const dispatch = useDispatch();

  const removeSubWorkTaskFromList = async () => {
    if (task && task.id && task?.subWorkTasks && task.subWorkTasks.length > 0) {
      let newTask = task;
      newTask.subWorkTasks = (await TaskService.getTask(parseInt(task.id))).subWorkTasks;
      setSubWorkTasks(newTask.subWorkTasks);

      if (subWorkTasks?.length === 0) {
        dispatch(setListShouldUpdate(true));
      }
    }
  };

  const splitSubWorkTasks = () => {
    const lausTasks: SubWorkTaskDTO[] = subWorkTasks
      ? subWorkTasks?.filter((sw) => sw.taskTypeId === parseInt(window._env_.LAUS_TASK_ID))
      : [];
    const otherTasks: SubWorkTaskDTO[] = subWorkTasks
      ? subWorkTasks?.filter((sw) => sw.taskTypeId !== parseInt(window._env_.LAUS_TASK_ID))
      : [];

    return { lausTasks, otherTasks };
  };

  useEffect(() => {
    if (task?.subWorkTasks) {
      splitSubWorkTasks();
    }
  }, [task?.subWorkTasks]);

  return (
    <>
      {task && task.subTaskId && (
        <Section>
          <SectionHeader>Hovedopgave</SectionHeader>
          <StyledSectionContent>
            <ColumnFlex3>
              <TaskDetailsStepRelatedTask
                taskId={parseInt(task.id ?? '')}
                taskStatus={task.mainTaskStatus as WorkTaskStatus}
                assignedTechnician={task.mainTaskAssignedToName}
                index={0}
                relationDescription="Tekniker"
                taskRelationType={TaskRelationType.MAINTASK}
                key={task.id}
                releaseWorkTask={() => releaseWorkTaskLock(parseInt(task.subTaskId ?? ''))}
              />
            </ColumnFlex3>
          </StyledSectionContent>
        </Section>
      )}
      {task &&
        !task.subTaskId &&
        ((task?.subWorkTasks && task.subWorkTasks.length > 0) || (task.relatedTasks && task.relatedTasks.length > 1)) && (
          <Section>
            <SectionHeader>Relaterede opgaver</SectionHeader>
            {task?.subWorkTasks && task.subWorkTasks.length > 0 && (
              <>
                <Typography variant="h6" fontWeight="bold">
                  Underopgaver
                </Typography>
                <StyledSectionContent>
                  <ColumnFlex3>
                    {splitSubWorkTasks().lausTasks.length > 0 &&
                      splitSubWorkTasks().lausTasks.map((sw, index) => {
                        return (
                          <TaskDetailsStepRelatedTask
                            taskId={sw.id}
                            taskStatus={sw.status}
                            assignedTechnician={sw.assignedTechnician}
                            index={index}
                            isSubTask
                            relationDescription="LAUS-mand"
                            taskRelationType={TaskRelationType.SUBTASK}
                            key={sw.id}
                            removeSubWorkTask={removeSubWorkTaskFromList}
                            releaseWorkTask={() => releaseWorkTaskLock(parseInt(task.id ?? ''))}
                          />
                        );
                      })}
                    {splitSubWorkTasks().otherTasks.length > 0 &&
                      splitSubWorkTasks().otherTasks.map((sw, index) => {
                        return (
                          <TaskDetailsStepRelatedTask
                            taskId={sw.id}
                            taskStatus={sw.status}
                            assignedTechnician={sw.assignedTechnician}
                            isSubTask
                            index={index}
                            relationDescription="Tekniker"
                            taskRelationType={TaskRelationType.SUBTASK}
                            key={sw.id}
                            releaseWorkTask={() => releaseWorkTaskLock(parseInt(task.id ?? ''))}
                          />
                        );
                      })}
                  </ColumnFlex3>
                </StyledSectionContent>
              </>
            )}
            {task?.relatedTasks && task.relatedTasks?.length > 1 && (
              <>
                <SubSectionHeader variant="h6" fontWeight="bold">
                  Relationsopgaver
                </SubSectionHeader>
                <StyledSectionContent>
                  <ColumnFlex3>
                    {task.relatedTasks.map((rt, index) => {
                      return (
                        <TaskDetailsStepRelatedTask
                          taskId={rt.workTaskId}
                          taskStatus={rt.status}
                          assignedTechnician={rt.assignedTechnician}
                          index={index}
                          relationDescription={
                            rt.workTaskId === parseInt(task?.id ?? '') ? 'Denne opgave' : rt.taskTypeName ?? ''
                          }
                          taskRelationType={TaskRelationType.RELATIONALTASK}
                          key={rt.workTaskId}
                          removeSubWorkTask={removeSubWorkTaskFromList}
                          releaseWorkTask={() => releaseWorkTaskLock(parseInt(task.id ?? ''))}
                          level={rt.level}
                          activeTask={rt.workTaskId === parseInt(task?.id ?? '')}
                        />
                      );
                    })}
                  </ColumnFlex3>
                </StyledSectionContent>
              </>
            )}
          </Section>
        )}
    </>
  );
};

const SubSectionHeader = styled(Typography)`
  margin-top: ${(props) => props.theme.spacing(2)};
`;
