import { useEffect, useState } from 'react';
import styled from 'styled-components';

import TimeRegTableTask from '../../../../blocks/time-registration-list/TimeRegTableTask';
import TimeRegistrationService from '../../../../services/TimeRegistrationService';
import CircularProgress from '../../../../components/circular-progress/CircularProgress';
import NotificationService from '../../../../services/NotificationService';
import { TabProps } from '../../../../blocks/tabs-vertical/TabsVertical';
import { ProjectDTO2, TimeRegistrationDTO } from '../../../../api/api';
import { AddIcon } from '../../../../assets/icons/AddIcon';
import { useConfirmationDialog } from '../../../../hooks/useConfirmationDialog';
import { DialogBody } from '../../../../stateManagement/reducers/confirmDialogReducer';
import { useDispatch } from 'react-redux';
import {
  addTimeRegistration,
  editTimeRegistration,
  TimeRegistrationSO
} from '../../../../stateManagement/reducers/timeRegistrationReducer';
import TimeRegForm from '../../../../blocks/timereg-form/TimeRegForm';
import IconButton from '../../../../components/icon-button/IconButton';
import Button from '../../../../components/button/Button';
import { log } from '../../../../utils/logging/log';
import TaskService from '../../../../services/TaskService';
import Typography from '../../../../components/typography/Typography';
import { ChevronLeft } from '../../../../assets/icons/ChevronLeft';

const dialogBodyDelete: DialogBody = {
  headerText: '',
  bodyText: 'Vil du slette den valgte tidsregistrering?',
  declineButtonText: 'Fortryd',
  confirmButtonText: 'Bekræft'
};

type Mode = 'standard' | 'edit';

interface Props extends TabProps {
  idForFetchingProject: string;
  idForRegistration: number;
}

const TaskTimeRegStep = (props: Props) => {
  const { idForFetchingProject, idForRegistration } = props;

  const [registrations, setRegistrations] = useState<TimeRegistrationDTO[]>([]);
  const [viewState, setViewState] = useState<'loading' | 'ready' | 'error'>('loading');
  const [mode, setMode] = useState<Mode>('standard');
  const [project, setProject] = useState<ProjectDTO2>();

  const { getConfirmation } = useConfirmationDialog();

  const dispatch = useDispatch();

  const fetchRegistrations = (id: number) => {
    setViewState('loading');
    TimeRegistrationService.getTaskTimeRegistrations(id)
      .then((result) => setActiveRegistrations(result))
      .catch((error) => log(error))
      .finally(() => setViewState('ready'));
  };

  const setActiveRegistrations = (result: TimeRegistrationDTO[]) => {
    setRegistrations(result.filter((t) => !t.isDeleted));
  };

  useEffect(() => {
    setViewState('loading');
    const projectNumberPromise = TaskService.getProjectById(idForFetchingProject).then((result) => setProject(result));

    const timeRegistrationsPromise = TimeRegistrationService.getTaskTimeRegistrations(idForRegistration).then((result) =>
      setActiveRegistrations(result)
    );

    Promise.all([projectNumberPromise, timeRegistrationsPromise])
      .then(() => setViewState('ready'))
      .catch((error) => {
        log(error);
        setViewState('error');
      });
  }, [idForRegistration, idForFetchingProject]);

  const totalHours = () => {
    if (registrations && registrations.length > 0) {
      return registrations
        .map((reg) => reg.hours)
        .reduce((first, second) => {
          return (first ?? 0) + (second ?? 0);
        });
    } else {
      return 0;
    }
  };

  const handleDeleteRegistration = async (regId: number) => {
    const confirmed = await getConfirmation(dialogBodyDelete);

    if (confirmed === 'confirm') {
      setViewState('loading');
      TimeRegistrationService.deleteTimeRegistration(regId)
        .then((result) => {
          if (idForRegistration) {
            fetchRegistrations(idForRegistration);
            NotificationService.success(`Timeregistrering blev slettet`);
          }
        })
        .catch((error) => {
          log(error);
          setViewState('loading');
          NotificationService.error(`Kunne ikke slette timeregistrering ${regId}: ${error}`);
        });
    }
  };

  const handleEditRegistration = (timeReg: TimeRegistrationSO) => {
    if (!idForRegistration) return;
    dispatch(editTimeRegistration({ project: project, workTaskId: idForRegistration, ...timeReg }));
    setMode('edit');
  };

  const handleAddRegistration = () => {
    if (!idForRegistration) return;
    dispatch(addTimeRegistration({ project: project, workTaskId: idForRegistration }));
    setMode('edit');
  };

  const handleReturn = () => {
    setMode('standard');
    idForRegistration && fetchRegistrations(idForRegistration);
  };

  if (mode === 'edit')
    return (
      <>
        <Container>
          <Header>
            <IconButton onClick={handleReturn}>
              <ChevronLeft size="18px" />
            </IconButton>
            <h3>Tilføj timer</h3>
          </Header>
          <TimeRegForm handleReturn={handleReturn} />
        </Container>
      </>
    );

  return (
    <Container>
      {viewState === 'loading' && <CircularProgress />}
      {viewState === 'ready' && (
        <>
          <StyledHeader>
            <h3>Timer registreret på opgaven {`(${totalHours()} timer)`}</h3>
            <Button onClick={handleAddRegistration} data-testid={'task-details-add-hours-button'}>
              <AddIcon size="20px" />
              Tilføj timer
            </Button>
          </StyledHeader>
          <TimeRegTableTask
            handleEdit={(timeReg: TimeRegistrationSO) => handleEditRegistration(timeReg)}
            handleDelete={(regId) => handleDeleteRegistration(regId)}
            timeRegistrations={registrations}
          />
        </>
      )}
      {viewState === 'error' && <Typography variant="h5"> Der kunne ikke hentes tidsregistreringer.</Typography>}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 0;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 0;
  column-gap: 12px;
`;

export default TaskTimeRegStep;
