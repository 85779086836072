import {
  AssetType,
  AssetType2,
  ConcurrentTaskTypeEnum,
  InputType,
  QuestionType,
  Region,
  Region2,
  TimeOfDay,
  Voltage,
  WorkTaskStatus,
  SubWorkTaskType,
  GisRegion2,
  OrderByColumnEnum,
  Department2,
  Priority,
  NewsDTONewsType,
  NewsDTONewsTarget,
  WorkInvoiceProjectCategory,
  Role,
  ProfileDTO2Role
} from '../api/api';
import { ColumnAccessors } from '../views/task-view/task-list-view/useTaskList';

export type EnumType =
  | AssetType
  | AssetType2
  | Department2
  | Region
  | Region2
  | GisRegion2
  | WorkTaskStatus
  | TimeOfDay
  | ConcurrentTaskTypeEnum
  | Voltage
  | WorkInvoiceProjectCategory
  | InputType
  | QuestionType
  | SubWorkTaskType
  | OrderByColumnEnum
  | ColumnAccessors
  | Priority
  | NewsDTONewsType
  | NewsDTONewsTarget
  | Role
  | ProfileDTO2Role;

export const getEnumDisplayValue = (enumType: EnumType): string => {
  switch (enumType) {
    case AssetType.InstallationNumber:
      return 'Installationsnummer';
    case AssetType.CabelBox:
      return 'Kabelskab - LSP';
    case AssetType.Netstation:
      return 'Netstation - MSP';
    case AssetType.HighVoltageNetstation:
      return '60kV station - HSP';

    case AssetType2.InstallationNumber:
      return 'Installationsnummer';
    case AssetType2.CabelBox:
      return 'Kabelskab - LSP';
    case AssetType2.Netstation:
      return 'Netstation - MSP';
    case AssetType2.HighVoltageNetstation:
      return '60kV station - HSP';

    case Department2.CustomerConnection:
      return 'Kundetilslutning';
    case Department2.Documentation:
      return 'Dokumentation';
    case Department2.MeterWorkshop:
      return 'Målerværksted';
    case Department2.Meterdata:
      return 'Målerdata';
    case Department2.ControlRoom:
      return 'Kontrolrummet';
    case Department2.Planning:
      return 'Planlægning';
    case Department2.TechnicalSupport:
      return 'Teknisk Support';
    case Department2.AdministrativeSupport:
      return 'Administrativ Support';
    case Department2.SupportCenter:
      return 'Kundeservice & support';
    case Department2.NetworkDesign:
      return 'Netdesign';
    case Department2.ProjectEstablishment:
      return 'Anlæg projekt 20-0.4';
    case Department2.ProjectExecution:
      return 'Anlæg udforelse 20-0.4';
    case Department2.Scada:
      return 'SCADA';
    case Department2.Automation:
      return 'Automation';
    case Department2.Maintenance:
      return 'Drift & Vedligehold 20-0.4';
    case Department2.OnsiteSupport:
      return 'Onsite Support';

    case Region.North:
    case Region2.North:
      return 'Nord';
    case Region.South:
    case Region2.South:
      return 'Syd';
    case Region.OneERP:
    case Region2.OneERP:
      return 'OneERP';

    case WorkTaskStatus.Created:
      return 'Oprettet';
    case WorkTaskStatus.ReadyForPlanning:
      return 'Klar til planlægning';
    case WorkTaskStatus.Planned:
      return 'Planlagt';
    case WorkTaskStatus.PlannedAppointment:
      return 'Planlagt (aftale)';
    case WorkTaskStatus.OnRoute:
      return 'På rute';
    case WorkTaskStatus.Ongoing:
      return 'Igangværende';
    case WorkTaskStatus.Pause:
      return 'Pause';
    case WorkTaskStatus.Completed:
      return 'Udført';
    case WorkTaskStatus.Processed:
      return 'Afsluttet';
    case WorkTaskStatus.Canceled:
      return 'Annulleret';

    case TimeOfDay.Morning:
      return 'Morgen (07:00-09:00)';
    case TimeOfDay.Noon:
      return 'Formiddag (09:00-11:00)';
    case TimeOfDay.Midday:
      return 'Middag (12:00-14:00)';
    case TimeOfDay.Afternoon:
      return 'Eftermiddag (14:00-16:00)';

    case ConcurrentTaskTypeEnum.MainTask:
      return 'Første-mand';
    case ConcurrentTaskTypeEnum.SubWorkTask:
      return '2. mand';

    case Voltage.Low:
    case WorkInvoiceProjectCategory.LowVoltage:
      return 'LSP';
    case Voltage.Medium:
    case WorkInvoiceProjectCategory.MediumVoltage:
      return 'MSP';
    case Voltage.High:
    case WorkInvoiceProjectCategory.HighVoltage:
      return 'HSP';
    case WorkInvoiceProjectCategory.Meter:
      return 'Måler';

    case InputType.Email:
      return 'Email';
    case InputType.Text:
      return 'Tekst';
    case InputType.Stretch:
      return 'Strækninger';
    case InputType.Number:
      return 'Nummer';
    case InputType.YesNo:
      return 'Ja/Nej';
    case InputType.DateTime:
      return 'Tidspunkt';

    case QuestionType.Confirm:
      return 'Bekræft';
    case QuestionType.Date:
      return 'Dato';
    case QuestionType.SingleChoice:
      return 'Valgmuligheder (enkelt)';
    case QuestionType.Header:
      return 'Header';
    case QuestionType.Image:
      return 'Billede';
    case QuestionType.Number:
      return 'Nummer';
    case QuestionType.Text:
      return 'Tekst';
    case QuestionType.DateTime:
      return 'Tidspunkt';

    case SubWorkTaskType.NoSubWorkTask:
      return 'Nej';
    case SubWorkTaskType.LiveVoltageTask:
      return 'Ja, med LAUS-mand';
    case SubWorkTaskType.SameAsMainTask:
      return 'Ja, med tekniker';

    case OrderByColumnEnum.WorkTaskId:
      return 'Id';
    case OrderByColumnEnum.Type:
      return 'Opgavetype';
    case OrderByColumnEnum.TaskLocation:
      return 'Lokation';
    case OrderByColumnEnum.EarliestStartDate:
      return 'Startdato';
    case OrderByColumnEnum.Deadline:
      return 'Slutdato';
    case OrderByColumnEnum.PlannedArrival:
      return 'Planlagt udførsel';
    case OrderByColumnEnum.CreatedAt:
      return 'Oprettelsesdato';

    case OrderByColumnEnum.CreatedByName:
      return 'Opgaveopretter';
    case OrderByColumnEnum.AssignedToName:
      return 'Tekniker';
    case OrderByColumnEnum.AssignedToEmail:
      return 'Tekniker email';
    case OrderByColumnEnum.AssignedToCaseWorkerName:
      return 'Sagsbehandler';
    case OrderByColumnEnum.Status:
      return 'Status';
    case OrderByColumnEnum.AssetId:
      return 'Asset Id';
    case OrderByColumnEnum.AssetType:
      return 'Asset type';
    case OrderByColumnEnum.AssignedToDepartment:
      return 'Afdeling';
    case OrderByColumnEnum.ProjectNumber:
      return 'Projektnummer';
    case OrderByColumnEnum.NotesForPlanning:
      return 'Bemærkning til planlægningen';
    case OrderByColumnEnum.AppointmentRequest:
      return 'Ønsker til tidsaftale';
    case OrderByColumnEnum.GisRegion:
      return 'Region';
    case OrderByColumnEnum.ZipCode:
      return 'Postnummer';
    case OrderByColumnEnum.AppointmentTimeStart:
      return 'Aftalt starttidspunkt';
    case OrderByColumnEnum.AppointmentTimeEnd:
      return 'Aftalt sluttidspunkt';
    case OrderByColumnEnum.AssignedToCaseWorkerEmail:
      return 'Tildelt sagsbehandler email';
    case OrderByColumnEnum.CreatedByEmail:
      return 'Oprettet af email';
    case OrderByColumnEnum.CustomerAppointment:
      return 'Kundeaftale';
    case OrderByColumnEnum.HasRelatedTasks:
      return 'Har relationsopgave(r)';
    case OrderByColumnEnum.HasSubTasks:
      return 'Har underopgaver(r)';
    case OrderByColumnEnum.PlannedDuration:
      return 'Varighed';
    case OrderByColumnEnum.ParentId:
      return 'Parent ID';
    case OrderByColumnEnum.CompletedDate:
      return 'Udførselsdato';

    case Priority.Normal:
      return 'Normal';
    case Priority.Low:
      return 'Lav';
    case Priority.High:
      return 'Høj';
    case Priority.Urgent:
      return 'Akut';

    case NewsDTONewsType.OperationalError:
      return 'Driftsfejl';
    case NewsDTONewsType.ResolvedError:
      return 'Driftsfejl løst';
    case NewsDTONewsType.Standard:
      return 'Nyhed';

    case Role.Technician:
    case ProfileDTO2Role.Technician:
    case NewsDTONewsTarget.Technician:
      return 'Tekniker';
    case Role.Office:
    case ProfileDTO2Role.Office:
    case NewsDTONewsTarget.Office:
      return 'Kontormedarbejder';
    case Role.CombinedTechnicianAndOffice:
    case ProfileDTO2Role.CombinedTechnicianAndOffice:
      return 'Kombirolle: tekniker + kontor';
    case Role.Admin:
    case ProfileDTO2Role.Admin:
    case NewsDTONewsTarget.Admin:
      return 'Administrator';
    case Role.Developer:
    case ProfileDTO2Role.Developer:
      return 'Udvikler';
    case NewsDTONewsTarget.All:
      return 'Alle';

    default:
      return enumType;
  }
};

export const columnAccessorsToColumnEnum = (enumType: EnumType): EnumType => {
  switch (enumType) {
    case ColumnAccessors.Id:
      return OrderByColumnEnum.WorkTaskId;
    case ColumnAccessors.TaskType:
      return OrderByColumnEnum.Type;
    case ColumnAccessors.Location:
      return OrderByColumnEnum.TaskLocation;
    case ColumnAccessors.ZipCode:
      return OrderByColumnEnum.ZipCode;
    case ColumnAccessors.AssetId:
      return OrderByColumnEnum.AssetId;
    case ColumnAccessors.AssetType:
      return OrderByColumnEnum.AssetType;
    case ColumnAccessors.NotesForPlanning:
      return OrderByColumnEnum.NotesForPlanning;
    case ColumnAccessors.EarliestStartDate:
      return OrderByColumnEnum.EarliestStartDate;
    case ColumnAccessors.Deadline:
      return OrderByColumnEnum.Deadline;
    case ColumnAccessors.Status:
      return OrderByColumnEnum.Status;
    case ColumnAccessors.Department:
      return OrderByColumnEnum.AssignedToDepartment;
    case ColumnAccessors.ProjectNumber:
      return OrderByColumnEnum.ProjectNumber;
    case ColumnAccessors.CreatedBy:
      return OrderByColumnEnum.CreatedByName;
    case ColumnAccessors.CreationDate:
      return OrderByColumnEnum.CreatedAt;
    case ColumnAccessors.PlannedDuration:
      return OrderByColumnEnum.PlannedDuration;
    case ColumnAccessors.PlannedArrival:
      return OrderByColumnEnum.PlannedArrival;
    case ColumnAccessors.AssignedTo:
      return OrderByColumnEnum.AssignedToName;
    case ColumnAccessors.AssignedToEmail:
      return OrderByColumnEnum.AssignedToEmail;
    case ColumnAccessors.CaseWorker:
      return OrderByColumnEnum.AssignedToCaseWorkerName;
    case ColumnAccessors.Region:
      return OrderByColumnEnum.GisRegion;
    case ColumnAccessors.AppointmentRequest:
      return OrderByColumnEnum.AppointmentRequest;
    case ColumnAccessors.CompletedAt:
      return OrderByColumnEnum.CompletedDate;

    default:
      return enumType;
  }
};

export const mapEnum = <T extends {}>(object: T): (keyof T)[] => {
  return Object.keys(object) as (keyof T)[];
};
