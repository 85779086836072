import { FormDialogContainer, Main } from '../../AdminTaskTypesView';
import { TabProps } from '../../../../../blocks/tabs-vertical/TabsVertical';
import styled from 'styled-components';
import UserService from '../../../../../services/UserService';
import { log } from '../../../../../utils/logging/log';
import NotificationService from '../../../../../services/NotificationService';
import TextField from '../../../../../components/text-field/TextField';
import { Row, SectionHeader } from '../../../../../styling/FormStyling';
import Select, { MenuItem } from '../../../../../components/select/Select';
import { getEnumDisplayValue } from '../../../../../utils/enumUtils';
import AutoComplete from '../../../../../components/auto-complete/AutoComplete';
import {
  Priority,
  ProjectDTO2,
  SubWorkTaskType,
  TaskTypeCategoryDTO,
  TaskTypePickerDTO2,
  UserDTO,
  Voltage
} from '../../../../../api/api';
import { useCallback, useEffect, useState } from 'react';
import { convertHoursAndMinutesToMinutes, convertMinutesToHoursAndMinutes } from '../../../../../utils/timeHandling';
import SimpleTime from '../../../../../components/simple-time/SimpleTime';
import { CreateAndUpdateTaskTypeDTO2Extended } from '../../../../../models/CreateAndUpdateTaskTypeDTOExtended';
import ProjectNumberDropdown, {
  MappedProjectNumber,
  projectNumberToOption
} from '../../../../../components/project-number-dropdown/ProjectNumberDropdown';
import ProjectsService from '../../../../../services/ProjectsService';
import CircularProgress from '../../../../../components/circular-progress/CircularProgress';
import TaskTypeService from '../../../../../services/TaskTypeService';
interface Props extends TabProps {
  properties: CreateAndUpdateTaskTypeDTO2Extended;
  setProperties: (properties: CreateAndUpdateTaskTypeDTO2Extended) => void;
  specificAssetTypes: string[];
  taskTypeCategories: TaskTypeCategoryDTO[];
}

const TaskTypeProperties = (props: Props) => {
  const { properties, setProperties, specificAssetTypes, taskTypeCategories } = props;
  const [users, setUsers] = useState<UserDTO[]>([]);
  const [selectedUser, setSelectedUser] = useState<UserDTO | null>(null);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [projectNumberObject, setProjectNumberObject] = useState<MappedProjectNumber>();
  const [projects, setProjects] = useState<ProjectDTO2[]>([]);
  const [isLoadingProjectNumbers, setIsLoadingProjectNumbers] = useState(true);
  const [taskTypes, setTaskTypes] = useState<TaskTypePickerDTO2>();

  const [selectedVoltages, setSelectedVoltages] = useState([
    properties?.voltageLevelLow && Voltage.Low,
    properties?.voltageLevelMedium && Voltage.Medium,
    properties?.voltageLevelHigh && Voltage.High
  ]);

  useEffect(() => {
    setProperties({
      ...properties,
      voltageLevelHigh: selectedVoltages.some((obj) => obj === Voltage.High),
      voltageLevelMedium: selectedVoltages.some((obj) => obj === Voltage.Medium),
      voltageLevelLow: selectedVoltages.some((obj) => obj === Voltage.Low)
    });
  }, []);

  const updateForm = useCallback(
    (
      field: keyof CreateAndUpdateTaskTypeDTO2Extended,
      value: string | number | boolean | number[] | TaskTypeCategoryDTO[]
    ) => {
      setProperties({ ...properties, [field]: value });
    },
    [properties, setProperties]
  );

  const sortByFirstName = (a: UserDTO, b: UserDTO) => {
    if (!a.name) return 1;
    if (!b.name) return -1;

    return a.name.localeCompare(b.name);
  };

  useEffect(() => {
    if (users.length === 0) {
      setLoadingUsers(true);
      UserService.getAllKnownUsers()
        .then((res) => {
          setUsers(res.sort((a, b) => sortByFirstName(a, b)));
          setSelectedUser(res.find((u) => u.email === properties?.taskTypeOwnerEmail) ?? null);
        })
        .catch((err) => {
          log(err);
          NotificationService.error('Kunne ikke hente brugere');
        })
        .finally(() => setLoadingUsers(false));
    } else {
      setSelectedUser(users.find((u) => u.email === properties?.taskTypeOwnerEmail) ?? null);
    }
  }, [properties?.taskTypeOwner, properties?.taskTypeOwnerEmail, users]);

  useEffect(() => {
    if (projects.length === 0) {
      ProjectsService.getProjectsByCategory(undefined)
        .then((_projects: ProjectDTO2[]) => {
          setProjects(_projects);
          if (properties?.projectNumber_OneERP) {
            const selectedProject = _projects.find((p) => p.id === properties?.projectNumber_OneERP);
            selectedProject && setProjectNumberObject(projectNumberToOption(selectedProject));
          }
        })
        .catch((err) => {
          log(err);
        })
        .finally(() => {
          setIsLoadingProjectNumbers(false);
        });
    } else {
      setIsLoadingProjectNumbers(false);
    }
  }, [projects, properties?.projectNumber_OneERP, projectNumberObject]);

  useEffect(() => {
    TaskTypeService.getActiveTaskTypes()
      .then((response) => {
        setTaskTypes(response);
      })
      .catch((e) => {
        NotificationService.error('Kunne ikke hente opgavetyper');
        log(e);
      });
  }, []);

  const handleTaskTypeOwnerChange = useCallback(
    (value: UserDTO | null) => {
      const newOwner = users.find((user) => user.email === value?.email);
      setProperties({
        ...properties,
        taskTypeOwnerEmail: newOwner?.email,
        taskTypeOwner: newOwner?.name
      });
    },
    [users, setProperties, properties]
  );

  const timeChangeHandler = (hours: number, minutes: number) => {
    updateForm('normTimeMin', convertHoursAndMinutesToMinutes(hours, minutes));
  };

  const handleProjectNumberChange = (value: MappedProjectNumber | null) => {
    updateForm('projectNumber_OneERP', value?.value.id ?? '');
    setProjectNumberObject(value ?? undefined);
  };

  const handleSetSelectedVoltages = (v: Voltage[]) => {
    setSelectedVoltages(v);
    setProperties({
      ...properties,
      voltageLevelHigh: v.some((obj) => obj === Voltage.High),
      voltageLevelMedium: v.some((obj) => obj === Voltage.Medium),
      voltageLevelLow: v.some((obj) => obj === Voltage.Low)
    });
  };

  return (
    <Main>
      {isLoadingProjectNumbers && <CircularProgress />}
      {!isLoadingProjectNumbers && (
        <FormDialogContainer>
          <StyledRow>
            <TextField
              fullWidth
              value={properties?.name}
              label="Navn"
              onChange={(e) => updateForm('name', e.target.value)}
            />
          </StyledRow>
          <StyledRow>
            <TextField
              fullWidth
              value={properties?.description}
              label="Definition"
              onChange={(e) => updateForm('description', e.target.value)}
            />
          </StyledRow>
          <StyledRow>
            <TextField
              type={'number'}
              fullWidth
              value={properties?.slaDays}
              label="SLA-dage"
              onChange={(e) => updateForm('slaDays', e.target.value)}
            />
            <SimpleTime
              initialHours={convertMinutesToHoursAndMinutes(properties?.normTimeMin ?? 0).hours}
              initialMinutes={convertMinutesToHoursAndMinutes(properties?.normTimeMin ?? 0).minutes}
              onTimeChange={(hours, minutes) => timeChangeHandler(hours, minutes)}
            />
            <ProjectNumberDropdown
              value={projectNumberObject}
              projectNumberChange={(value: any) => handleProjectNumberChange(value)}
              options={projects}
              isLoadingProjectNumbers={isLoadingProjectNumbers}
              disabled={isLoadingProjectNumbers}
            />
          </StyledRow>
          <SectionHeader>Opgave kategorisering</SectionHeader>
          <StyledRow>
            <Select
              fullWidth
              value={selectedVoltages}
              label="Spændingsniveau"
              onChange={(e: any) => handleSetSelectedVoltages(e.target.value)}
              multiple
            >
              {Object.keys(Voltage).map((k) => (
                <MenuItem key={k} value={k}>
                  {getEnumDisplayValue(k as Voltage)}
                </MenuItem>
              ))}
            </Select>
            <Select
              fullWidth
              value={properties?.specificAssetType || ''}
              label="Assettype"
              onChange={(e: any) => updateForm('specificAssetType', e.target.value)}
            >
              {specificAssetTypes.map((s) => (
                <MenuItem value={s} key={s}>
                  {s}
                </MenuItem>
              ))}
            </Select>
          </StyledRow>
          <StyledRow>
            <Select
              fullWidth
              value={properties?.taskTypeCategoryIds ?? []}
              label="Kategori"
              onChange={(e: any) => updateForm('taskTypeCategoryIds', e.target.value)}
              multiple
            >
              {taskTypeCategories.map((t) => (
                <MenuItem value={t.id} key={t.id}>
                  {t.name}
                </MenuItem>
              ))}
            </Select>
            <Select
              fullWidth
              value={Number(properties?.emergencyOperationalFailure)}
              label="Akut driftsfejl"
              onChange={(e) => updateForm('emergencyOperationalFailure', e.target.value === 1)}
            >
              <MenuItem value={1}>Ja</MenuItem>
              <MenuItem value={0}>Nej</MenuItem>
            </Select>
          </StyledRow>
          <StyledRow>
            <Select
              fullWidth
              value={Number(properties?.enableContractorAgreements) || ''}
              label="Arbejdsinstruks"
              onChange={(e) => updateForm('enableContractorAgreements', e.target.value === 1)}
            >
              <MenuItem value={1}>Ja</MenuItem>
              <MenuItem value={0}>Nej</MenuItem>
            </Select>
            <Select
              fullWidth
              value={properties?.subWorkTaskType}
              defaultValue={SubWorkTaskType.NoSubWorkTask}
              label="2-mandsopgave"
              onChange={(e) => updateForm('subWorkTaskType', e.target.value)}
            >
              {Object.keys(SubWorkTaskType).map((s) => (
                <MenuItem key={s} value={s}>
                  {getEnumDisplayValue(s as SubWorkTaskType)}
                </MenuItem>
              ))}
            </Select>
          </StyledRow>
          <StyledRow>
            <TextField
              fullWidth
              value={properties?.flsCallTypeId || ''}
              label="FLS opgavetype ID"
              onChange={(e) => updateForm('flsCallTypeId', e.target.value)}
            />
          </StyledRow>
          <StyledRow>
            <AutoComplete
              loading={loadingUsers}
              loadingText="Henter brugere"
              fullWidth
              onChange={(event, value, reason) => handleTaskTypeOwnerChange(value)}
              getOptionLabel={(user) => user.name ?? ''}
              options={users}
              value={selectedUser}
              renderInput={(params) => <TextField {...params} label={'Ejer'} fullWidth />}
              noOptionsText={'Ingen medarbejder' ?? ''}
            />
            <Select
              fullWidth
              value={Number(properties?.enableStretches) || ''}
              label="Strækninger"
              onChange={(e) => updateForm('enableStretches', e.target.value === 1)}
            >
              <MenuItem value={1}>Ja</MenuItem>
              <MenuItem value={0}>Nej</MenuItem>
            </Select>
          </StyledRow>
          <StyledRow>
            <Select
              fullWidth
              value={Number(properties.ignoreDrivingTimeWhenPlanned)}
              label="Ignorer kørsel ved planlægning"
              onChange={(e) => updateForm('ignoreDrivingTimeWhenPlanned', e.target.value === 1)}
            >
              <MenuItem value={1}>Ja</MenuItem>
              <MenuItem value={0}>Nej</MenuItem>
            </Select>
            <Select
              fullWidth
              value={properties.priority}
              defaultValue={Priority.Normal}
              label="Prioritet"
              onChange={(e) => updateForm('priority', e.target.value)}
            >
              <MenuItem value={Priority.Normal}>Normal</MenuItem>
              <MenuItem value={Priority.Urgent}>Haster (kan afbryde andre opgaver)</MenuItem>
            </Select>
          </StyledRow>
          <StyledRow>
            <Select
              fullWidth
              value={Number(properties?.requiresPreparationTask)}
              label="Opret relationsopgave automatisk"
              onChange={(e) => updateForm('requiresPreparationTask', e.target.value === 1)}
            >
              <MenuItem value={1}>Ja</MenuItem>
              <MenuItem value={0}>Nej</MenuItem>
            </Select>
            {properties.requiresPreparationTask ? (
              <Select
                fullWidth
                value={properties.defaultRelationalTaskTypeId}
                label="Vælg relationsopgave"
                onChange={(e) => updateForm('defaultRelationalTaskTypeId', e.target.value)}
              >
                <MenuItem value={undefined}>Ingen valgt</MenuItem>
                {taskTypes?.taskTypeDTOs &&
                  taskTypes.taskTypeDTOs.map((taskType) => <MenuItem value={taskType.id}>{taskType.name}</MenuItem>)}
              </Select>
            ) : (
              <EmptyCell />
            )}
          </StyledRow>
        </FormDialogContainer>
      )}
    </Main>
  );
};

export const FormDialogButtonFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${(props) => props.theme.palette.grey.black10};
  padding-top: ${(props) => props.theme.spacing(5)};
  padding-right: ${(props) => props.theme.spacing(5)};
  width: 100%;
  position: absolute;
  bottom: 0px;
  background-color: white;
  z-index: ${(props) => props.theme.zIndex.dialogTools};
`;

export const FormDialogLeftButtons = styled.div`
  display: flex;
`;

const EmptyCell = styled.div`
  width: 100%;
`;

export const FormDialogRightButtons = styled.div`
  display: flex;
`;

export const CheckBoxDiv = styled.div`
  display: flex;
  align-items: center;
  margin: 0px ${(props) => props.theme.spacing(10)};
`;

const StyledRow = styled(Row)`
  margin: ${(props) => props.theme.spacing(4)} 0px 0px 0px;
`;

export default TaskTypeProperties;
