import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { useState } from 'react';
import styled from 'styled-components';
import { QuestionProps } from '../question-wrapper/QuestionRenderer';

import QuestionHeader from '../question-header/QuestionHeader';
import AuditInfo from '../audit-info/AuditInfo';
import QuestionRow from '../question-wrapper/QuestionRow';
import { getUserEmail } from '../../utils/authProvider/authProvider';
import { getInitialsFromEmail } from '../../utils/initialsUtils';

const QuestionCheckbox = (props: QuestionProps) => {
  const { question, handleSave, required, showMandatoryFieldError } = props;
  const [checked, setChecked] = useState<boolean>(question.value === 1 ? true : false);
  const [lastUpdatedBy, setLastUpdatedBy] = useState(question.updatedByEmail);
  const [lastUpdatedTime, setLastUpdatedTime] = useState(question.timeStampValue);

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    const newValue = !checked;
    setChecked(newValue);
    handleSave && handleSave(newValue ? 1 : undefined);
    setLastUpdatedBy(getInitialsFromEmail(getUserEmail()));
    setLastUpdatedTime(new Date().toISOString() + '');
  };

  return (
    <QuestionRow>
      <StyledFormGroup error={showMandatoryFieldError && required && !checked}>
        <StyledFormControlLabel
          label={
            <QuestionHeader
              text={question.text}
              required={required}
              helpText={question.helpText}
              error={showMandatoryFieldError && required && !checked}
            />
          }
          control={<StyledCheckbox checked={checked} onClick={handleClick} data-testid={question.id} />}
          labelPlacement="end"
        />
      </StyledFormGroup>
      <AuditInfo updatedByEmail={lastUpdatedBy} updatedTimeStamp={lastUpdatedTime} size="24px" />
    </QuestionRow>
  );
};

const StyledFormGroup = styled(({ error, ...props }) => <FormGroup {...props} />)<{
  error?: boolean;
}>`
  width: 100%;

  color: ${(props) => (props.error ? props.theme.palette.functions.error.primary : '')};
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  justify-content: flex-start;
`;

const StyledCheckbox = styled(Checkbox)`
  &.MuiCheckbox-colorSecondary.Mui-checked {
    color: ${(props) => props.theme.palette.grey.black90};
  }

  &.MuiCheckbox-colorSecondary.Mui-checked:hover {
    background-color: ${(props) => props.theme.palette.grey.black10};
  }

  &.MuiCheckbox-colorSecondary:hover {
    background-color: ${(props) => props.theme.palette.grey.black10};
  }
`;

export default QuestionCheckbox;
