import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ProductDTO, ProductDetailDTO, UpdateStockDTO } from '../../../api/api';

import { selectUserProfile } from '../../../stateManagement/reducers/userProfileReducer';

import styled from 'styled-components';
import Button from '../../../components/button/Button';
import Link from '../../../components/link/Link';
import Typography from '../../../components/typography/Typography';
import { SelectedGoods } from '../../../models/Goods';
import GoodsService from '../../../services/GoodsService';
import NotificationService from '../../../services/NotificationService';
import { log } from '../../../utils/logging/log';
import NavigationHeader from '../components/NavigationHeader';
import SelectedGoodsList from '../components/SelectedGoodsList';
import GoodsList from '../order-goods-dialog/goods-list/GoodsList';
import SelectedGoodsBadge from '../components/SelectedGoodsBadge';
import LoadingOverlay from '../../../components/loading-overlay/LoadingOverlay';
import BasketPopover from '../components/BasketPopover';
import BasicPopover from '../../../components/popover/Popover';

interface Props {
  setPage: (page: string) => void;
  page: string;
  onClose?: () => void;
}

const CountGoodsViewContent = (props: Props) => {
  const { setPage, page, onClose } = props;
  const vehicle = useSelector(selectUserProfile).userProfile.vehicle;

  const [loading, setLoading] = useState(false);

  const [goods, setGoods] = useState<SelectedGoods[]>([]);
  const [countedStock, setCountedStock] = useState<UpdateStockDTO[]>([]);

  const [completeGoodsList, setCompleteGoodsList] = useState<ProductDetailDTO[]>([]);
  const [selectedGoods, setSelectedGoods] = useState<SelectedGoods[]>([]);

  useEffect(() => {
    if (!vehicle?.legalEntityId || !vehicle.vehicleId) return;
    setLoading(true);
    GoodsService.getItemsOnHand3(vehicle?.legalEntityId, vehicle?.vehicleId, vehicle?.defaultIssueLocationId)
      .then((res) => {
        const tempStock = res.map((s) => {
          return {
            itemId: s.product?.itemId,
            name: s.product?.name,
            serialNumber: s.product?.serialNumber,
            batchNumber: s.product?.batchNumber,
            qty: s.available
          } as SelectedGoods;
        }) as SelectedGoods[];
        setGoods(tempStock);

        const _countedStock = res.map((s) => {
          return {
            product: {
              name: s.product?.name,
              itemId: s.product?.itemId,
              serialNumber: s.product?.serialNumber,
              batchNumber: s.product?.batchNumber
            } as ProductDTO,
            quantity: s.available
          } as UpdateStockDTO;
        });
        setCountedStock(_countedStock);
      })
      .catch((err) => {
        log(err);
        NotificationService.error('Bilens lager kunne ikke hentes');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setLoading, vehicle?.defaultIssueLocationId, vehicle?.legalEntityId, vehicle?.vehicleId]);

  const removeSelectedGoods = useCallback((goodsId?: string) => {
    if (!goodsId) return;
    setSelectedGoods((prevState) => [...prevState].filter((good) => good.itemId !== goodsId));
    setGoods((prevState) => [...prevState].filter((good) => good.itemId !== goodsId));
  }, []);

  const handleSetGoodsAmount = useCallback(
    (value?: number, goodsId?: string) => {
      if (!goodsId || !value) return;
      findAndReplaceItemInGoodsArray(value, goodsId);
      replaceOrInsertUpdatedStockCount(value, goodsId);
    },
    [completeGoodsList, countedStock, goods]
  );

  const replaceOrInsertUpdatedStockCount = (value?: number, goodsId?: string) => {
    const updateStock = countedStock.find((stock) => stock.product?.itemId === goodsId);
    if (updateStock) {
      const updatedCountedStock = [...countedStock].map((s) => {
        if (s.product?.itemId === goodsId) {
          return { ...s, quantity: value };
        }
        return s;
      });
      setCountedStock(updatedCountedStock);
    } else {
      const availableGood = completeGoodsList.find((g) => g.itemId === goodsId);
      const newUpdateStock = {
        product: {
          name: availableGood?.name,
          itemId: availableGood?.itemId,
          serialNumber: undefined,
          batchNumber: undefined
        } as ProductDTO,
        quantity: value
      } as UpdateStockDTO;
      setCountedStock([...countedStock, newUpdateStock]);
    }
  };

  const findAndReplaceItemInGoodsArray = (value?: number, goodsId?: string) => {
    const updatedGoodsArray = [...goods].map((g) => {
      if (g.itemId === goodsId) {
        return { ...g, qty: value };
      } else return g;
    });
    setGoods(updatedGoodsArray);
  };

  useEffect(() => {
    setLoading(true);
    GoodsService.getAllProducts()
      .then((products) => {
        setCompleteGoodsList(products);
      })
      .catch((error) => {
        log(error);
        NotificationService.error('Kunne ikke hente vareliste');
      })
      .finally(() => setLoading(false));
  }, []);

  const handleSelectGoods = useCallback(
    (goodsId?: string) => {
      if (!goodsId) return;
      var existingGood = goods.find((g) => g.itemId === goodsId);
      if (existingGood) {
        NotificationService.warning(`${existingGood.name} er allerede registreret`);
        return;
      }
      const { name, itemId } = completeGoodsList.find((good) => good?.itemId === goodsId) ?? {};
      const goodsToAdd: SelectedGoods = { name, itemId, qty: 0 };
      setSelectedGoods((prevState) => [...prevState, goodsToAdd]);
      NotificationService.success(`${name} registreret`);
      goods.push(goodsToAdd);
    },
    [completeGoodsList, goods]
  );

  const handleOnCounted = useCallback(() => {
    if (!vehicle || !vehicle?.legalEntityId || !vehicle?.vehicleId) {
      NotificationService.error('Der er sket en fejl i registeringen af vareoptællingen', 5000);
      return;
    }
    GoodsService.updateWarehouseStock(
      vehicle?.legalEntityId,
      vehicle?.vehicleId,
      countedStock,
      vehicle?.defaultIssueLocationId
    )
      .then(() => {
        NotificationService.success('Vareoptællingen er registeret', 5000);
      })
      .catch((error) => {
        log(error);
        NotificationService.error('Der er sket en fejl i registeringen af vareoptællingen', 5000);
      })
      .finally(() => {
        setLoading(false);
        onClose && onClose();
      });
  }, [countedStock, onClose, vehicle]);

  const ActionButtons = useCallback(() => {
    if (page === 'count') {
      return (
        <>
          <TextBoks onClick={() => setPage('add')}>
            <Typography variant="h6">
              Har du varetyper der ikke er på listen?
              <StyledLink
                onClick={() => {
                  setPage('add');
                  setSelectedGoods([]);
                }}
                minWidth="80px"
              >
                Slå dem op her
              </StyledLink>
            </Typography>
          </TextBoks>
          <StyledButton onClick={handleOnCounted}>Registrer varer</StyledButton>
        </>
      );
    } else {
      return <></>;
    }
  }, [handleOnCounted, page, setPage]);

  return (
    <Container>
      {loading && <LoadingOverlay />}
      <NavigationHeader
        headerTitle={page === 'add' ? 'Tilføj varetype til bilens lager' : 'Lav optælling'}
        onBackButton={page === 'add' ? () => setPage('count') : undefined}
        onClose={onClose}
      />
      <ContentContainer>
        {page === 'count' && (
          <>
            <SelectedGoodsList
              selectedGoods={goods}
              setGoodsAmount={handleSetGoodsAmount}
              showGoodsIcon={false}
              showAvailable={false}
            />
          </>
        )}
        {page === 'add' && <StyledGoodsList goods={completeGoodsList} selectGoods={handleSelectGoods} />}
      </ContentContainer>
      {
        <Footer>
          {page === 'add' && (
            <>
              <Space />
              <BasicPopover
                buttonElement={<SelectedGoodsBadge show count={selectedGoods.length} />}
                popoverElement={<BasketPopover removeGoods={removeSelectedGoods} selectedGoods={selectedGoods} />}
                disablePopover={selectedGoods.length === 0}
                activeByClick
              />
            </>
          )}
          {page === 'count' && <ActionButtons />}
        </Footer>
      }
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  height: 100%;
  width: 100%;
`;

const ContentContainer = styled.div`
  background: white;
  width: calc(100% - 50px);
  height: calc(100% - 182px);
  padding-top: 10px;
`;

const Footer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${(props) => props.theme.palette.grey.black5};
  width: 100%;
  height: 100px;
  bottom: 0;
`;

const StyledButton = styled(Button)`
  margin: 0px 20px;
`;

const StyledGoodsList = styled(GoodsList)`
  .table-footer {
    justify-content: end;
  }
`;

const TextBoks = styled.div`
  display: flex;
  padding-left: 30px;
`;
const Space = styled.div`
  display: flex;
`;

const StyledLink = styled(Link)`
  display: inline-block;
  position: relative;
  z-index: ${(props) => props.theme.zIndex.main};
  padding: 1em;
  margin: -1em;
`;

export default CountGoodsViewContent;
