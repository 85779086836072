import { useMemo, useCallback, useState } from 'react';
import { CellProps, Column, HeaderProps, Row } from 'react-table';
import {
  BaseWorkTaskTypeEnum,
  WorkTaskStatus,
  TimeOfDay,
  DepartmentDTO,
  TaskListRequestDTO,
  OrderByEnum,
  WorkTaskListItemDTO3,
  TaskListResponseDTO,
  OrderByColumnEnum
} from '../../../api/api';
import { RelationIcon } from '../../../assets/icons/RelationIcon';
import { ScheduleIcon } from '../../../assets/icons/ScheduleIcon';
import { TableTab } from '../../../blocks/table/TableUtils';
import StatusTag from '../../../components/status-tag/StatusTag';
import useTableInstance from '../../../hooks/useTableInstance';
import n1LightTheme, { N1LightTheme } from '../../../theme/light-theme';
import { formatDate, formatDateString, getDistanceInDaysFromToday, sortDateTime } from '../../../utils/dateHandling';
import { getEnumDisplayValue } from '../../../utils/enumUtils';
import { getLocationString } from '../../../utils/location/locationHandling';
import { SearchColumnFilter, SelectColumnFilter, DateRangeColumnFilter } from './TaskListFilterUtils';

import { PeopleIcon } from '../../../assets/icons/PeopleIcon';
import { Tab, Tabs } from '../../../blocks/table/TableServer';
import { ClockIcon } from '../../../assets/icons/ClockIcon';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectFilterValues,
  selectOperationBaseFilter,
  selectSelectedCustomFilters,
  selectTags,
  setFilterValues,
  setSelectedAllCustomFilter,
  setSelectedCustomFilter,
  setShowFilterButton
} from '../../../stateManagement/reducers/taskListReducer';
import { selectUserProfile } from '../../../stateManagement/reducers/userProfileReducer';
import { dateRangeFilterFn, IconCell, Icons, multiSelectFilterFn } from '../../../blocks/table/TableFilterUtils';
import { CommentIcon } from '../../../assets/icons/CommentIcon';
import { LayerIcon } from '../../../assets/icons/LayerIcon';
import styled, { useTheme } from 'styled-components';
import Checkbox from '../../../components/checkbox/Checkbox';
import ToolTip from '../../../components/tool-tip/ToolTip';

export const DefaultTabs = {
  Drift: { header: 'Drift', id: -1 },
  All: { header: 'Alle opgaver', id: -2 }
};

export enum ColumnAccessors {
  Id = 'id',
  TaskType = 'taskType',
  Location = 'location',
  ZipCode = 'zipCode',
  AssetId = 'assetId',
  AssetType = 'assetType',
  NotesForPlanning = 'notesForPlanning',
  EarliestStartDate = 'earliestStartDate',
  Deadline = 'deadline',
  Status = 'status',
  Department = 'department',
  ProjectNumber = 'projectNumber',
  CreatedBy = 'createdBy',
  CreationDate = 'creationDate',
  CompletedAt = 'completedAt',
  PlannedDuration = 'plannedDuration',
  AssignedTo = 'assignedTo',
  AssignedToEmail = 'assignedToEmail',
  CaseWorker = 'caseWorker',
  PlannedArrival = 'plannedArrival',
  Region = 'region',
  AppointmentRequest = 'appointmentRequest'
}

enum CaseWorkerCustomSearch {
  HasValue = 'Tildelt',
  NoValue = 'Ikke tildelt'
}

export interface TaskData {
  checkbox?: string;
  id: string;
  taskType: string;
  location: string;
  zipCode: number | string;
  region?: string;
  assetId: string;
  assetType: string;
  status: string;
  department: string;
  notesForPlanning: string;
  earliestStartDate?: string;
  deadline?: string;
  projectNumber: string;
  createdBy: string;
  creationDate?: string;
  plannedDuration: string;
  assignedTo: string;
  caseWorker: string;
  hasRelatedTasks: boolean;
  appointment?: string;
  plannedArrival?: string;
  baseWorkTaskType?: BaseWorkTaskTypeEnum;
  hasSubTasks?: boolean;
  appointmentRequest?: string;
  completedAt?: string;
  tag?: string;
  flaggedCommentText?: string;
}

export interface FilterValues {
  id: string;
  value: any;
}

export const defaultColumn = {
  minWidth: 125,
  width: 175
};

const caseWorkerSearchFn = (rows: Row<any>[], id: any, filterValue: string = '') => {
  if (!filterValue) return rows;
  if (filterValue === CaseWorkerCustomSearch.HasValue) {
    return rows.filter((row) => row.values[id] !== '-');
  }
  if (filterValue === CaseWorkerCustomSearch.NoValue) {
    return rows.filter((row) => row.values[id] === '-');
  }
  return rows.filter((row) => row.values[id].toLowerCase().includes(filterValue?.toLowerCase()));
};

const formatCustomerAppointmentToastText = (
  approvedByPlanning?: boolean,
  preferredTimeOfDay?: TimeOfDay,
  startDate?: string,
  endDate?: string
) => {
  const date =
    startDate && endDate
      ? 'd. ' + formatDateString(startDate) + ' - ' + formatDateString(endDate)
      : startDate
      ? 'd. ' + formatDateString(startDate)
      : endDate
      ? 'd. ' + formatDateString(endDate)
      : '';

  let timeOfDay = date !== '' ? ', ' : '';
  timeOfDay = preferredTimeOfDay ? timeOfDay + getEnumDisplayValue(preferredTimeOfDay) : '';

  return `${approvedByPlanning ? 'Godkendt' : 'Ønske om'} kundeaftale ` + date + timeOfDay;
};

export const useTaskList = (tasks: WorkTaskListItemDTO3[]) => {
  const userDepartment = useSelector(selectUserProfile).userProfile.department;
  const [tableConfig, setTableConfig] = useState<TaskListResponseDTO>({} as TaskListResponseDTO);
  const dispatch = useDispatch();

  const filterValues = useSelector(selectFilterValues);

  const data = useMemo(() => {
    if (!tasks) return [];

    return tasks.map((task) => {
      return {
        id: task.id ?? '-',
        taskType: task.type ?? '-',
        location: task.taskLocation ? getLocationString(task.taskLocation) : '-',
        zipCode: task.taskLocation?.postalCode ? parseInt(task.taskLocation?.postalCode) : '-',
        region: task.gisRegion && getEnumDisplayValue(task.gisRegion),
        assetId: task.assetId ?? '-',
        assetType: task.assetType ? getEnumDisplayValue(task.assetType) : '-',
        status: task.status ? getEnumDisplayValue(WorkTaskStatus[task.status]) : '-',
        department: task.assignedToDepartment?.name ?? '-',
        notesForPlanning: task.notesForPlanning ?? '-',
        earliestStartDate: task.earliestStartDate,
        deadline: task.deadline,
        projectNumber: task.projectNumber ?? '-',
        createdBy: task.createdByName ?? '-',
        creationDate: task.createdAt,
        plannedDuration: task.plannedDuration + '' ?? '-',
        assignedTo: task.assignedToName ?? '-',
        assignedToEmail: task.assignedToEmail ?? '-',
        caseWorker: task.assignedToCaseWorkerName ?? '-',
        hasRelatedTasks: task.hasRelatedTasks,
        appointment:
          task.customerAppointment &&
          (task?.customerAppointment?.preferredTimeOfDay ||
            task.customerAppointment.startDate ||
            task.customerAppointment.endDate)
            ? formatCustomerAppointmentToastText(
                task?.customerAppointment?.approvedByPlanning,
                task?.customerAppointment?.preferredTimeOfDay,
                task?.customerAppointment?.startDate,
                task?.customerAppointment?.endDate
              )
            : undefined,
        plannedArrival: task.plannedArrival,
        baseWorkTaskType: task.baseWorkTaskType,
        hasSubTasks: task.hasSubTasks,
        appointmentRequest: task.customerAppointment?.appointmentRequest ? 'Ja' : 'Nej',
        completedAt: task.completedDate,
        tag: task.tagId,
        flaggedCommentText: task.flaggedCommentText
      } as TaskData;
    });
  }, [tasks]);

  const columns = useMemo(() => {
    return [
      {
        Header: (header: HeaderProps<TaskData>) => {
          const { rows, toggleRowSelected } = header;

          const filteredRows = rows.filter((row) => row.original.status !== getEnumDisplayValue(WorkTaskStatus.Canceled));

          const toggleFiltered = (value: boolean) => {
            filteredRows.forEach((row) => {
              toggleRowSelected(row.id, value);
            });
          };

          const toggleRows = () => {
            if (filteredRows.every((row) => row.isSelected)) {
              toggleFiltered(false);
            } else {
              toggleFiltered(true);
            }
          };

          const getChecked = () => {
            if (filteredRows.length === 0) return false;
            return filteredRows.every((obj) => obj.isSelected);
          };

          return <Checkbox checked={getChecked()} onChange={toggleRows} />;
        },
        accessor: 'checkbox',
        width: 50,
        disableFilters: true,
        disableSortBy: true,
        Cell: (cellProps: CellProps<TaskData>) => {
          const { cell } = cellProps;
          return (
            <Checkbox
              checked={cell.row.isSelected}
              onChange={() => cell.row.toggleRowSelected()}
              disabled={cell.row.original.status === getEnumDisplayValue(WorkTaskStatus.Canceled)}
            />
          );
        }
      },
      {
        Header: getEnumDisplayValue(OrderByColumnEnum.WorkTaskId),
        accessor: ColumnAccessors.Id,
        width: 160,
        minWidth: 160,
        maxWidth: 160,
        Filter: SearchColumnFilter,
        filter: 'text',
        Cell: (cellProps: CellProps<TaskData>) => {
          const { cell } = cellProps;
          return (
            <IconCell width={160}>
              {cell.value}
              {cell.row.original.flaggedCommentText && (
                <ToolTip title={cell.row.original.flaggedCommentText}>
                  <div>
                    <CommentIcon
                      size="18px"
                      minWidth="11px"
                      padding="0 5px 0 5px"
                      color={n1LightTheme.palette.functions.warning.primary}
                    />
                  </div>
                </ToolTip>
              )}
            </IconCell>
          );
        }
      },
      {
        Header: getEnumDisplayValue(OrderByColumnEnum.Type),
        accessor: ColumnAccessors.TaskType,
        width: 210,
        minWidth: 210,
        Filter: (tableInstance) => SelectColumnFilter(tableInstance, false),
        filter: multiSelectFilterFn,
        Cell: (cellProps: CellProps<TaskData>) => {
          const { cell } = cellProps;
          return (
            <IconCell width={210}>
              {cell.value}
              <Icons>
                {cell.row.original.hasRelatedTasks && (
                  <ToolTip title="Opgaven har relationsopgaver">
                    <div>
                      <RelationIcon size="16px" minWidth="16px" padding="0 5px 0 15px" />
                    </div>
                  </ToolTip>
                )}
                {cell.row.original.hasSubTasks && (
                  <ToolTip title="Flere personer påkrævet ">
                    <div>
                      <PeopleIcon size="16px" minWidth="16px" padding="0 5px 0 15px" />
                    </div>
                  </ToolTip>
                )}
              </Icons>
            </IconCell>
          );
        }
      },
      {
        Header: getEnumDisplayValue(OrderByColumnEnum.EarliestStartDate),
        accessor: ColumnAccessors.EarliestStartDate,
        width: 125,
        minWidth: 125,
        Filter: (_tableInstance, x) => DateRangeColumnFilter(_tableInstance, true),
        filter: dateRangeFilterFn,
        sortType: (a, b) => sortDateTime(a.values.earliestStartDate, b.values.earliestStartDate),
        Cell: (cellProps: CellProps<TaskData>) => {
          const { cell } = cellProps;
          return (
            <IconCell>
              {cell.value ? formatDate(new Date(cell.value)) ?? '-' : '-'}
              <Icons>
                {cell.row.original.appointment && (
                  <ToolTip title={cell.row.original.appointment}>
                    <div>
                      <ScheduleIcon
                        size="16px"
                        padding="0 5px 0 15px"
                        color={n1LightTheme.palette.functions.warning.primary}
                      />
                    </div>
                  </ToolTip>
                )}
              </Icons>
            </IconCell>
          );
        }
      },
      {
        Header: getEnumDisplayValue(OrderByColumnEnum.Deadline),
        accessor: ColumnAccessors.Deadline,
        width: 125,
        minWidth: 125,
        Filter: (tableInstance, x) => DateRangeColumnFilter(tableInstance, false, true),
        filter: dateRangeFilterFn,
        sortType: (a, b) => sortDateTime(a.values.deadline, b.values.deadline),
        Cell: (cellProps: CellProps<TaskData>) => {
          const { cell } = cellProps;
          return (
            <IconCell>
              {cell.value ? formatDate(new Date(cell.value)) ?? '-' : '-'}
              {/* Show Icon if there is a week or less to the deadline, and the task is in another status than 'Afsluttet' or 'Udført' */}
              <Icons>
                {getDistanceInDaysFromToday(new Date(cell.value)) <= 7 &&
                  cellProps.cell.row.allCells.filter((c) => {
                    if (c.column.Header === 'Status') {
                      return c.value === 'Afsluttet' || c.value === 'Udført';
                    }
                  }).length === 0 && (
                    <ToolTip title={'Der er snart deadline på opgaven'}>
                      <div>
                        <ClockIcon
                          size="16px"
                          padding="0 10px 0 5px"
                          color={n1LightTheme.palette.functions.warning.primary}
                        />
                      </div>
                    </ToolTip>
                  )}
              </Icons>
            </IconCell>
          );
        }
      },
      {
        Header: getEnumDisplayValue(OrderByColumnEnum.TaskLocation),
        accessor: ColumnAccessors.Location,
        width: 150,
        minWidth: 150,
        Filter: SearchColumnFilter,
        filter: 'text'
      },
      {
        Header: getEnumDisplayValue(OrderByColumnEnum.ZipCode),
        accessor: ColumnAccessors.ZipCode,
        width: 100,
        minWidth: 100,
        Filter: SearchColumnFilter,
        filter: 'text'
      },
      {
        Header: getEnumDisplayValue(OrderByColumnEnum.GisRegion),
        accessor: ColumnAccessors.Region,
        width: 125,
        minWidth: 125,
        Filter: SelectColumnFilter,
        filter: multiSelectFilterFn
      },
      {
        Header: getEnumDisplayValue(OrderByColumnEnum.AppointmentRequest),
        accessor: ColumnAccessors.AppointmentRequest,
        width: 125,
        minWidth: 125,
        Filter: (tableInstance) => SelectColumnFilter(tableInstance, true),
        filter: multiSelectFilterFn
      },
      {
        Header: getEnumDisplayValue(OrderByColumnEnum.AssetId),
        accessor: ColumnAccessors.AssetId,
        width: 125,
        minWidth: 125,
        Filter: SearchColumnFilter,
        filter: 'text'
      },
      {
        Header: getEnumDisplayValue(OrderByColumnEnum.AssetType),
        accessor: ColumnAccessors.AssetType,
        width: 150,
        minWidth: 150,
        Filter: SelectColumnFilter,
        filter: multiSelectFilterFn
      },
      {
        Header: getEnumDisplayValue(OrderByColumnEnum.NotesForPlanning),
        accessor: ColumnAccessors.NotesForPlanning,
        width: 250,
        minWidth: 250,
        Filter: SearchColumnFilter,
        filter: 'text'
      },
      {
        Header: getEnumDisplayValue(OrderByColumnEnum.Status),
        accessor: ColumnAccessors.Status,
        width: 125,
        minWidth: 125,
        Filter: SelectColumnFilter,
        Cell: (cellProps: CellProps<TaskData>) => {
          const { cell } = cellProps;
          return <StatusTag statusValue={cell.value}>{cell.value}</StatusTag>;
        },
        filter: multiSelectFilterFn
      },
      {
        Header: getEnumDisplayValue(OrderByColumnEnum.AssignedToDepartment),
        accessor: ColumnAccessors.Department,
        width: 125,
        minWidth: 125,
        Filter: SelectColumnFilter,
        filter: multiSelectFilterFn
      },
      {
        Header: getEnumDisplayValue(OrderByColumnEnum.ProjectNumber),
        accessor: ColumnAccessors.ProjectNumber,
        width: 175,
        minWidth: 175,
        Filter: SearchColumnFilter,
        filter: 'text'
      },
      {
        Header: getEnumDisplayValue(OrderByColumnEnum.CreatedByName),
        accessor: ColumnAccessors.CreatedBy,
        width: 175,
        minWidth: 175,
        Filter: SearchColumnFilter,
        filter: 'text'
      },
      {
        Header: getEnumDisplayValue(OrderByColumnEnum.CreatedAt),
        accessor: ColumnAccessors.CreationDate,
        width: 125,
        minWidth: 125,
        Filter: (_tableInstance, x) => DateRangeColumnFilter(_tableInstance, true),
        filter: dateRangeFilterFn,
        sortType: (a, b) => sortDateTime(a.values.earliestStartDate, b.values.earliestStartDate),
        Cell: (cellProps: CellProps<TaskData>) => {
          const { cell } = cellProps;
          return <IconCell>{cell.value ? formatDate(new Date(cell.value)) ?? '-' : '-'}</IconCell>;
        }
      },
      {
        Header: getEnumDisplayValue(OrderByColumnEnum.PlannedArrival),
        accessor: ColumnAccessors.PlannedArrival,
        width: 175,
        minWidth: 175,
        Filter: (_tableInstance, x) => DateRangeColumnFilter(_tableInstance, true),
        filter: dateRangeFilterFn,
        sortType: (a, b) => sortDateTime(a.values.plannedArrival, b.values.plannedArrival),
        Cell: (cellProps: CellProps<TaskData>) => {
          const { cell } = cellProps;
          return <div>{cell.value ? formatDate(new Date(cell.value)) ?? '-' : '-'}</div>;
        }
      },
      {
        Header: getEnumDisplayValue(OrderByColumnEnum.AssignedToName),
        accessor: ColumnAccessors.AssignedTo,
        width: 150,
        minWidth: 150,
        Filter: SearchColumnFilter,
        filter: 'text'
      },
      {
        Header: getEnumDisplayValue(OrderByColumnEnum.AssignedToEmail),
        accessor: ColumnAccessors.AssignedToEmail,
        width: 150,
        minWidth: 150,
        Filter: SearchColumnFilter,
        filter: 'text'
      },
      {
        Header: getEnumDisplayValue(OrderByColumnEnum.AssignedToCaseWorkerName),
        accessor: ColumnAccessors.CaseWorker,
        width: 150,
        minWidth: 150,
        Filter: SearchColumnFilter,
        filter: caseWorkerSearchFn
      },
      {
        Header: getEnumDisplayValue(OrderByColumnEnum.CompletedDate),
        accessor: ColumnAccessors.CompletedAt,
        width: 175,
        minWidth: 175,
        Filter: (_tableInstance, x) => DateRangeColumnFilter(_tableInstance, true),
        filter: dateRangeFilterFn,
        sortType: (a, b) => sortDateTime(a.values.completedAt, b.values.plannedArrival),
        Cell: (cellProps: CellProps<TaskData>) => {
          const { cell } = cellProps;
          return <div>{cell.value ? formatDate(new Date(cell.value)) ?? '-' : '-'}</div>;
        }
      }
    ] as Column<TaskData>[];
  }, []);

  const tableInstance = useTableInstance<TaskData>(data, columns, {
    initialState: {
      pageSize: 25,
      pageIndex: 0
    }
  });

  const tags = useSelector(selectTags);
  const selectedCustomFilters = useSelector(selectSelectedCustomFilters);
  const operationBaseFilter = useSelector(selectOperationBaseFilter);

  const changeTab = useCallback(
    (department?: DepartmentDTO, tabId?: number) => {
      const allTasks = !!(tabId && tabId === DefaultTabs.All.id) ?? false;
      const tagId = allTasks ? undefined : tabId;

      let changeTabValues = {
        page: 1,
        pageSize: filterValues.pageSize,
        sortOrder: OrderByEnum.Desc,
        tagId: tagId
      } as TaskListRequestDTO;

      if (!allTasks && department) {
        changeTabValues = {
          page: 1,
          pageSize: filterValues.pageSize,
          sortOrder: OrderByEnum.Desc,
          ...operationBaseFilter,
          tagId: tagId
        } as TaskListRequestDTO;
      }

      if (!allTasks && selectedCustomFilters?.operation) {
        changeTabValues = {
          page: 1,
          pageSize: filterValues.pageSize,
          sortOrder: OrderByEnum.Desc,
          ...(selectedCustomFilters.operation as TaskListRequestDTO),
          tagId: tagId
        };
      }

      if (allTasks && selectedCustomFilters?.all) {
        changeTabValues = {
          page: 1,
          pageSize: filterValues.pageSize,
          sortOrder: OrderByEnum.Desc,
          ...(selectedCustomFilters.all as TaskListRequestDTO),
          tagId: tagId
        };
      }

      allTasks
        ? dispatch(setSelectedAllCustomFilter(selectedCustomFilters?.all ?? undefined))
        : dispatch(setSelectedCustomFilter(selectedCustomFilters?.operation ?? undefined));

      dispatch(setFilterValues(changeTabValues));
      dispatch(setShowFilterButton(false));
    },
    [dispatch, filterValues.pageSize, operationBaseFilter, selectedCustomFilters]
  );

  const taskListTabs: TableTab[] = useMemo(() => {
    let tabs: TableTab[] = [
      {
        id: DefaultTabs.Drift.id,
        header: DefaultTabs.Drift.header,
        filter: () => {},
        disabledColumns: [ColumnAccessors.Department]
      }
    ];

    tags.forEach((t) => {
      if (t.tagText && t.id)
        tabs.push({
          id: t.id,
          header: t.tagText,
          filter: () => {},
          disabledColumns: [ColumnAccessors.Department]
        });
    });

    tabs.push({
      id: DefaultTabs.All.id,
      header: DefaultTabs.All.header,
      filter: () => {}
    });

    return tabs;
  }, [tags]);

  const [activeTab, setActiveTab] = useState<TableTab | undefined>(taskListTabs?.length ? taskListTabs[0] : undefined);
  const theme = useTheme() as N1LightTheme;
  const tabs = useMemo(() => {
    return (
      <>
        {taskListTabs?.length && (
          <Tabs>
            {taskListTabs.map((tab) => {
              const active = activeTab?.header === tab.header;
              const tabTextColor = active ? theme.palette.text.primary : theme.palette.grey.black60;
              const isAllTasks = tab?.id === DefaultTabs.All.id;
              return (
                <Tab
                  active={active}
                  textColor={tabTextColor}
                  onClick={() => {
                    setActiveTab(taskListTabs.find((t) => t.header === tab.header));
                    changeTab(userDepartment, tab.id);
                  }}
                  key={tab.header}
                  data-testid={`list-tab-${tab.id}`}
                >
                  {tab?.header && (
                    <h4>
                      {isAllTasks && <StyledLayerIcon color={tabTextColor} size="18px" />} {tab?.header}
                    </h4>
                  )}
                </Tab>
              );
            })}
          </Tabs>
        )}
      </>
    );
  }, [taskListTabs, activeTab?.header, changeTab, userDepartment]);

  const excelRowFilter = useCallback((row: Row<TaskData>, activeTab: string) => {
    if (activeTab !== DefaultTabs.All.header) {
      return row.values[OrderByColumnEnum.Status] !== getEnumDisplayValue(WorkTaskStatus.Processed);
    }
    return true;
  }, []);

  return {
    tableInstance,
    taskListTabs,
    excelRowFilter,
    tabs,
    activeTab,
    filterValues,
    tableConfig,
    setTableConfig,
    changeTab
  };
};

const StyledLayerIcon = styled(LayerIcon)`
  margin-right: ${(props) => props.theme.spacing(3)};
`;
